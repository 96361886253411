import React, { Component } from 'react';
import classNames from 'classnames';
import withStyles  from '@material-ui/core/styles/withStyles';
import {Paper, Typography, List, ListItem, ListItemText, Table, TableBody} from '@material-ui/core';
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import {connect} from "react-redux";
import {StyledCircularProgress} from "../../components/Progress";
import {RowData} from "../../components/RowData";
import {shortId} from "../../util/functions";
import {getPlanAction} from "../../redux/actions";


const styles = theme => ({
    paper: {
        marginBottom: '10px'
    }

});
class PlanPage extends React.Component {

    componentWillMount() {
        const id = this.props.match.params.id;
        if (id) {
            this.props.getPlanAction(id)
        }
    }

    render() {
        const {plan, classes, isLoading} = this.props;

        const planIsLoading = isLoading || !plan;


        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Toolbar>
                        <Typography variant="title">Plan</Typography>
                    </Toolbar>
                </Paper>
                { planIsLoading ? (
                    <StyledCircularProgress />
                ) : (

                    <PlanView {...this.props}/>

                )}
            </React.Fragment>

        )
    }
}


const PlanView = ({plan, classes}) => (
    <Paper className={classes.paper}>
        <Toolbar>
            <Typography variant="subheading" color="textSecondary">Properties</Typography>
        </Toolbar>
        <Table><TableBody>
            <RowData name="ID" value={shortId(plan.id)}/>
            <RowData name="Name" value={plan.name}/>
            <RowData name="Event Type" value={plan.eventType}/>
        </TableBody></Table>
    </Paper>
);

const mapStateToProps = (state) => ({
    isLoading: state.plans.isLoading,
    plan: state.plans.selected,
})

PlanPage = connect(mapStateToProps, {getPlanAction})(PlanPage);

export default withStyles(styles)(PlanPage);