import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Button} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
    },
});

class LoginButton extends React.Component {

    componentDidMount() {
        console.log("login button mounted, state is", this.state)
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Button variant="raised" color="primary" className={classes.button}>
                    Login
                </Button>
            </div>
        )
    }
}

LoginButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginButton)