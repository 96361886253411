import {API_FETCH, handleErrors} from "./apiActions";
import {SNACK_OPEN} from "./snackActions";

export const SOLUTION_SELECTED = 'SOLUTION_SELECTED';

export const FETCH_SOLUTION_BEGIN = 'FETCH_SOLUTION_BEGIN';
export const FETCH_SOLUTION_SUCCESS  = 'FETCH_SOLUTION_SUCCESS';
export const FETCH_SOLUTION_FAILURE = 'FETCH_SOLUTION_FAILURE';

export const FETCH_SOLUTIONS_BEGIN = 'FETCH_SOLUTIONS_BEGIN';
export const FETCH_SOLUTIONS_SUCCESS  = 'FETCH_SOLUTIONS_SUCCESS';
export const FETCH_SOLUTIONS_FAILURE = 'FETCH_SOLUTIONS_FAILURE';


export const getSolutionAction = (id) => {
    return async (dispatch) => {
        dispatch({type: FETCH_SOLUTION_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/solutions/${id}`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_SOLUTION_SUCCESS, solution: json});
                dispatch({type: SOLUTION_SELECTED, id: id});
            })
            .catch(error => {
                dispatch({type: FETCH_SOLUTION_FAILURE, error: error});
                dispatch({type: SNACK_OPEN, message: error.message})
            });
    }
};


export const getSolutionsAction = () => {
    return async (dispatch) => {
        dispatch({type: FETCH_SOLUTIONS_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/solutions`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_SOLUTIONS_SUCCESS, solutions: json.content});
            })
            .catch(error => {
                dispatch({type: FETCH_SOLUTIONS_FAILURE, error: error});
                dispatch({type: SNACK_OPEN, message: error.message})
            });
    }
};
