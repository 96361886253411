import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {snackShowAction, snackDismissAction, snackUndoAction} from "../redux/actions";
import {connect} from "react-redux";

const styles = theme => ({
    close: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
});


const SnackBarContainer = (props) => (

    <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        open={props.open}
        autoHideDuration={4000}
        onClose={props.handleClose}
        SnackbarContentProps={{
            'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{props.message}</span>}
        action={[
            // props.undoAction &&
            // <Button key="undo" color="secondary" size="small" onClick={props.handleUndo}>
            //     UNDO
            // </Button>,
            <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                // className={classes.close}
                onClick={props.handleClose}
            >
                <CloseIcon />
            </IconButton>,
        ]}
    />
);

// Redux Work
const mapStateToProps = (state) => {
    return {
        open: state.snacks.open,
        message: state.snacks.message
        // undo: state.snacks.message
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        handleClose() { dispatch(snackDismissAction())},
        handleUndo() { dispatch(snackUndoAction())}
    }
};

let styled = withStyles(styles)(SnackBarContainer);

export default connect(mapStateToProps, mapDispatchToProps)(styled)

