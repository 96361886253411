import {
    FETCH_MARKET_SUMMARIES_BEGIN,
    FETCH_MARKET_SUMMARIES_SUCCESS,
    FETCH_MARKET_SUMMARIES_FAILURE
} from '../actions/marketActions'

const initialState = {
    records: [],
    selected: null,
    lists: {
        filtered: []
    },
    loading: false,
    error: null
};


export default function marketSummariesReducer(state = initialState, action) {
    switch(action.type) {

        case FETCH_MARKET_SUMMARIES_BEGIN:
            return { ...state, isLoading: true, error: null };

        // On success add collection if action.resource is null, otherwise individual object
        case FETCH_MARKET_SUMMARIES_SUCCESS:
            return { ...state, records: action.marketSummaries, isLoading: false, error: null };

        // On failure keep the records you already had, display the error
        case FETCH_MARKET_SUMMARIES_FAILURE:
            return { ...state, records: [...state.records], isLoading: false, error: action.error };

        default:
            return state
    }
}