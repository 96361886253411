import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import {connect} from "react-redux";
import {getMarketsAction} from "../../redux/actions/index";
import {StyledCircularProgress} from "../../components/Progress";
import { Link }  from "react-router-dom";
import {Paper, Toolbar, Typography, Table, TableBody, TableCell, TableHead, TableRow, List, ListItem, ListItemText } from "@material-ui/core";
import {FormattedDate, FormattedTime} from "react-intl";
import {FormattedDateTime} from "../../components/FormattedDateTime";

const styles = theme => ({

});

class MarketsPage extends Component {

    componentWillMount() {
        this.props.getMarketsAction()
    }

    render() {

        const {isLoading, markets} = this.props;


        return isLoading? <StyledCircularProgress /> : <MarketsList markets={markets}/>



    }
}

export const MarketsList = ({markets}) => (
    <Paper>
        <Toolbar>
            <Typography variant="title">Markets</Typography>
        </Toolbar>

        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell numeric>Default Price</TableCell>
                    <TableCell numeric>Fail Cost</TableCell>
                    <TableCell>Market Type</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {markets.map(market => {
                    return (
                        <TableRow key={market.id}>
                            <TableCell> <Link to={`/markets/${market.id}`}>{market.id}</Link></TableCell>
                            <TableCell>{market.name}</TableCell>
                            <TableCell numeric>{market.defaultPrice}</TableCell>
                            <TableCell numeric>{market.failCost}</TableCell>
                            <TableCell>{market.marketType}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </Paper>
);

const mapStateToProps = (state) => ({
    isLoading: state.markets.isLoading,
    markets: state.markets.records,
})

export default connect(mapStateToProps, {getMarketsAction})(MarketsPage);

// export default withStyles(styles)(MarketsPage);