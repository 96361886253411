import {TableCell, TableRow, Typography} from "@material-ui/core";
import {FormattedDateTime} from "./FormattedDateTime";
import * as PropTypes from "prop-types";
import React from "react";

export function RowData(props) {
    return <TableRow>
        <TableCell><Typography >{props.name}</Typography></TableCell>
        <TableCell>
            <Typography color="textSecondary">
                { props.date ? <FormattedDateTime value={props.value}/> : props.value }
            </Typography>
        </TableCell>
    </TableRow>;
}
RowData.propTypes = {id: PropTypes.string, value: PropTypes.any};