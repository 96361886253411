import { API_FETCH, handleErrors} from "./apiActions";
import { SNACK_OPEN } from "./snackActions";

export const PLAN_SELECTED = 'PLAN_SELECTED';

export const FETCH_PLAN_BEGIN = 'FETCH_PLAN_BEGIN';
export const FETCH_PLAN_SUCCESS  = 'FETCH_PLAN_SUCCESS';
export const FETCH_PLAN_FAILURE = 'FETCH_PLAN_FAILURE';

export const FETCH_PLANS_BEGIN = 'FETCH_PLANS_BEGIN';
export const FETCH_PLANS_SUCCESS  = 'FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_FAILURE = 'FETCH_PLANS_FAILURE';


export const getPlanAction = (id) => {
    return async (dispatch) => {
        dispatch({type: FETCH_PLAN_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/plans/${id}`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_PLAN_SUCCESS, plan: json});
                dispatch({type: PLAN_SELECTED, id: id});
            })
            .catch(error => {
                dispatch({type: FETCH_PLAN_FAILURE, error: error});
                dispatch({type: SNACK_OPEN, message: error.message})
            });
    }
};


export const getPlansAction = () => {
    return async (dispatch) => {
        dispatch({type: FETCH_PLANS_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/plans`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_PLANS_SUCCESS, plans: json.content});
            })
            .catch(error => {
                dispatch({type: FETCH_PLANS_FAILURE, error: error});
                dispatch({type: SNACK_OPEN, message: error.message})
            });
    }
};
