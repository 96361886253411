import {API_FETCH, API_ERROR, fetchAction, handleErrors, extractIdFromHeader} from "./apiActions";
import {snackShowAction} from "./index";

import fetchRetry from "../../util/fetch-retry"
import {SNACK_OPEN} from "./snackActions";

export const RESOURCE_CREATE_FORM_SUBMIT = 'RESOURCE_CREATE_FORM_SUBMIT';
export const RESOURCE_SUBMITTING_TO_API = 'RESOURCE_SUBMITTING_TO_API';
export const RESOURCE_CREATED = 'RESOURCE_CREATED';

export const RESOURCES_LOADING = 'RESOURCES_LOADING';
export const RESOURCES_LOADED = 'RESOURCES_LOADED';
export const RESOURCE_LOADING = 'RESOURCE_LOADING';
export const RESOURCE_LOADED = 'RESOURCE_LOADED';

export const RESOURCE_SELECTED = 'RESOURCE_SELECTED';
export const RESOURCE_SCHEDULE_SELECTED = 'RESOURCE_SCHEDULE_SELECTED';
export const RESOURCE_READINGS_SELECTED = 'RESOURCE_READINGS_SELECTED';

export const FETCH_RESOURCE_BEGIN = 'FETCH_RESOURCE_BEGIN';
export const FETCH_RESOURCE_SUCCESS = 'FETCH_RESOURCE_SUCCESS';
export const FETCH_RESOURCE_FAILURE = 'FETCH_RESOURCE_FAILURE';
export const CREATE_RESOURCE_BEGIN = 'CREATE_RESOURCE_BEGIN';
export const CREATE_RESOURCE_SUCCESS = 'CREATE_RESOURCE_SUCCESS';
export const CREATE_RESOURCE_FAILURE = 'CREATE_RESOURCE_FAILURE';

export const FETCH_RESOURCE_SCHEDULE_BEGIN = 'FETCH_RESOURCE_SCHEDULE_BEGIN';
export const FETCH_RESOURCE_SCHEDULE_SUCCESS = 'FETCH_RESOURCE_SCHEDULE_SUCCESS';
export const FETCH_RESOURCE_SCHEDULE_FAILURE = 'FETCH_RESOURCE_SCHEDULE_FAILURE';

export const FETCH_RESOURCE_READINGS_BEGIN = 'FETCH_RESOURCE_READINGS_BEGIN';
export const FETCH_RESOURCE_READINGS_SUCCESS = 'FETCH_RESOURCE_READINGS_SUCCESS';
export const FETCH_RESOURCE_READINGS_FAILURE = 'FETCH_RESOURCE_READINGS_FAILURE';

// https://daveceddia.com/where-fetch-data-redux/
export function saveResourceAction(values) {
    return async function(dispatch){

        dispatch({type: RESOURCE_SUBMITTING_TO_API, values: values});
        const response = await dispatch(fetchAction(`/api/resources`, {method: 'POST'}, values));

        if (response.status === 201) {
            let id = extractIdFromHeader(response);
            const resource = await dispatch(getResourceAction(id));

            dispatch({ type: RESOURCE_CREATED, resource: resource });
            dispatch(snackShowAction("Resource Created"));
        } else if (response.status === 400) {
            console.log('bad request')
            dispatch({ type: API_ERROR, error: "bad request" });
        }
    }
};


export const getResourceAction = (id) => {
    return async (dispatch) => {
        dispatch({type: FETCH_RESOURCE_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/resources/${id}`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_RESOURCE_SUCCESS, resource: json});
                dispatch({type: RESOURCE_SELECTED, id: id});
            })
            .catch(error => {
                dispatch({type: FETCH_RESOURCE_FAILURE, error: error});
                dispatch({type: SNACK_OPEN, message: error.message})
            });
    }
};

export const getResourcesAction = () => {
    return async (dispatch) => {
        dispatch({type: FETCH_RESOURCE_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/resources`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_RESOURCE_SUCCESS, resources: json.content});
            })
            .catch(error => {
                dispatch({type: FETCH_RESOURCE_FAILURE, error: error});
                dispatch({type: SNACK_OPEN, message: error.message})
            });
    }
};

export const getResourceScheduleAction = (id) => {
    return async (dispatch) => {
        dispatch({type: FETCH_RESOURCE_SCHEDULE_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/resources/${id}/schedule`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_RESOURCE_SCHEDULE_SUCCESS, resourceSchedules: json});
                dispatch({type: RESOURCE_SCHEDULE_SELECTED, id: id});
            })
            .catch(error => {
                dispatch({type: FETCH_RESOURCE_SCHEDULE_FAILURE, error: error});
                dispatch({type: SNACK_OPEN, message: error.message})
            });
    }
};

export const getResourceReadingsAction = (id) => {
    return async (dispatch) => {
        dispatch({type: FETCH_RESOURCE_READINGS_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/resources/${id}/readings`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_RESOURCE_READINGS_SUCCESS, readings: json});
                dispatch({type: RESOURCE_READINGS_SELECTED, id: id});
            })
            .catch(error => {
                dispatch({type: FETCH_RESOURCE_READINGS_FAILURE, error: error});
                dispatch({type: SNACK_OPEN, message: error.message})
            });
    }
};
