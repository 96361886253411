import React from 'react';
import './App.css';
import { Provider } from 'react-redux'
import Layout from './Layout'
import EventSourceHandler from './EventSourceHandler'
import withRoot from './withRoot';
import {IntlProvider} from "react-intl";


import NoSsr from '@material-ui/core/NoSsr';

const Root = ({ store }) => (
    <Provider store={store}>
      <IntlProvider locale="en-GB">
      <NoSsr> {/* SSR is not needed https://material-ui.com/utils/no-ssr/ */}
        <React.Fragment>
          <Layout/>

        </React.Fragment>
          </NoSsr>
      </IntlProvider>
    </Provider>
)

export default withRoot(Root);
