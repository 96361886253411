export const FETCH_BEGIN = 'FETCH_BEGIN';
export const FETCH_END = 'FETCH_END';
export const FETCH_CANCEL = 'FETCH_CANCEL';
export const WEBSOCKET_CONNECT = 'WEBSOCKET_CONNECT';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT';

export const EVENTS_LOADED = 'EVENTS_LOADED';
export const EVENTS_LOADING = 'EVENTS_LOADING';

export const API_ERROR = 'API_ERROR';
export const API_FETCH = 'API_FETCH';


export function extractIdFromHeader(response) {
    let loc = response.headers.get('Location')
    // console.log('status is:', response.status, 'location is:', loc)
    let parts = loc.split('/');
    let id = parts[2];
    return id;
}

// Handle HTTP errors since fetch won't.
export function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

// Include both initial load via fetch, and also use of websockets connections to Webflux

export const fetchAction = (url, params, payload) => ({
    type: FETCH_BEGIN,
    url,
    params,
    payload
});

// const getUser = id => async ({ dispatch }) => {
//     const result = await dispatch(fetch(`http://api.website.com/${id}`, { method: 'GET' }));
// };

// export const eventsLoaded = (events) => ({dispatch}) => {
//     console.log("events are", json)
// }

// export const getEvents = () => async ({ dispatch }) => {
export const getEventsAction = () => {
    return async (dispatch) => {
        dispatch({type: EVENTS_LOADING})
        const result = await dispatch(fetchAction(`/api/events`, {method: 'GET'}));
        // const result = dispatch(fetch(`/api/events`, {method: 'GET'}));

        console.log('result is:', result)
        const events = await result.json()
        console.log('json is:', events)
        dispatch({ type: EVENTS_LOADED, events: events });
        // this.state
    }
};



// export function signInAction({ email, password }, history) {
//     return async (dispatch) => {
//         try {
//             // const res = await fetch`${URL}/signin`, { email, password });
//
//             let res = {data: {token: 'blah'}}
//
//             dispatch({ type: AUTHENTICATED });
//             localStorage.setItem('user', res.data.token);
//             history.push('/secret');
//         } catch(error) {
//             dispatch({
//                 type: AUTHENTICATION_ERROR,
//                 payload: 'Invalid email or password'
//             });
//         }
//     };
// }