import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import fetchMiddleware from '../util/fetchMiddleware'
import rootReducer from './reducers'
import { logger } from 'redux-logger'
import { checkAuthenticationToken } from './actions'
// import { createLogger } from 'redux-logger'

import DevTools from '../containers/DevTools'
import { persistState } from 'redux-devtools';

import {GenericStoreEnhancer} from "redux";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const enhancer = composeEnhancers(
    applyMiddleware(thunkMiddleware, fetchMiddleware(window.fetch))
);

/*
devToolsEnhancer = ({
        name: 'Cinet React', realtime: true,
    });
 */

// const INITIAL_STATE={}

export default function configureStore(initialState) {


    const store = createStore(rootReducer, initialState, enhancer);

    console.log("configuring Dev store variant")

    // store.dispatch({type: CHECK_AUTHENTICATION_TOKEN});
    store.dispatch(checkAuthenticationToken);
    // const store = createStore(rootReducer, enhancer);

    return store;
}

// export default function configureStore(initialState){
//     return applyMiddleware(thunkMiddleware)(createStore)(rootReducer, initialState)
//
// }
//
// import thunkMiddleware from 'redux-thunk'
// import { createStore, combineReducers, applyMiddleware } from 'redux'
//
//
// export default function configureStore(initialState){
//     return applyMiddleware(thunkMiddleware)(createStore)(rootReducer, initialState)
//
// }




// export default configureStore