import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {Drawer, Divider} from '@material-ui/core';
import AppBarTop  from './AppBarTop';
import AdminNavMenuList  from '../components/NavMenuList';
import WelcomeBanner  from '../components/WelcomeBanner';
import SnackBarContainer  from './Snackbar';
import { contentPaneRoutes } from '../routes'
import {API_HOST} from "../constants";
import EventSourceHandler from "./EventSourceHandler";
import ErrorBoundary from "../components/ErrorBoundary";


const styles = theme => ({
    root: {
        flexGrow: 1,
        // height: 430,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    appFrame: {
        // height: 430,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
        // alignItems: 'center'
    },

    drawerPaper: {
        position: 'relative',
        width: 240,
    },

    // navToolbar: theme.mixins.toolbar,
    navToolbar: {
        backgroundColor: theme.palette.background.default,
    },
    toolbar: theme.mixins.toolbar,

    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        minWidth: 0, // So the Typography noWrap works
        marginTop: 64, // Keep it below the AppBar
    },
    contentWrapper: {

    }
});

class Layout extends Component {

    render() {
        const { classes } = this.props;
        const authenticated = this.props.auth.authenticated;

        const drawer = (
            <Drawer
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <div className={classes.toolbar} />
                <Divider />
                {authenticated && <AdminNavMenuList/> }
            </Drawer>

    );

        return (
            <Router>
                <div className={classes.root}>
                    <div className={classes.appFrame}>
                        <AppBarTop/>
                        {/*<ErrorBoundary>*/}
                        {/*{authenticated &&*/}

                            {/*<EventSourceHandler sourceUrl={`${API_HOST}/api/events/stream`} auth={this.props.auth}/>*/}

                        {/*}*/}
                        {/*</ErrorBoundary>*/}
                        {drawer}
                        <main className={classes.content}>
                            <div className={classes.toolbar} >

                                {authenticated ? contentPaneRoutes : <WelcomeBanner/>}
                            </div>
                        </main>
                        <SnackBarContainer/>
                    </div>
                </div>
            </Router>
        );
    }
}


Layout.propTypes = {
    classes: PropTypes.object.isRequired,
};

// Redux Work
const mapStateToProps = (state) => {
    return { auth: state.auth };
};

let styledLayout = withStyles(styles)(Layout);
export default connect(mapStateToProps)(styledLayout)
