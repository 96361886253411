import React from 'react';
import PropTypes from 'prop-types';
import withStyles  from '@material-ui/core/styles/withStyles';
import {Typography, CardContent, CardMedia, Card} from '@material-ui/core';

const styles = {
    card: {
        maxWidth: 345,
    },
    media: {
        height: 130,
        // width: 200,
        // width: '100%',
    },
    welcomeWrapper: {
        display: 'flex',
        flex: 1,
        // flexDirection
        flexItemAlign: 'center',
        justifyContent: 'center',

    },
    welcome: {
        display: 'flex',
        // flex: 1,
        // alignSelf: 'flex-start'

    }
};

const WelcomeBanner = (props) => (
        <div className={props.classes.welcomeWrapper}>
            <div className={props.classes.welcome}>
                <Card className={props.classes.card}>
                    <CardMedia
                        className={props.classes.media}
                        image={process.env.PUBLIC_URL + "/ContactEnergy-logo2013.png"}
                        title="Contact Energy"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="headline" component="h2">
                            Welcome to EnergyNet
                        </Typography>
                        <Typography component="p">
                            Please sign in to get started, or contact the New Ventures Team for access
                        </Typography>
                    </CardContent>
                    {/*<CardActions>*/}
                        {/*<Button size="small" color="primary">*/}
                            {/*Share*/}
                        {/*</Button>*/}
                        {/*<Button size="small" color="primary">*/}
                            {/*Learn More*/}
                        {/*</Button>*/}
                    {/*</CardActions>*/}
                </Card>
            </div>
        </div>
);

WelcomeBanner.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WelcomeBanner);