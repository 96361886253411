import React from 'react';
import {Paper, Typography} from '@material-ui/core';
import {Charts, ChartContainer, ChartRow, Resizable, EventChart, Legend, styler} from "react-timeseries-charts";
import { TimeSeries, TimeRange, TimeRangeEvent } from "pondjs";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    chartWrapper: {
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '10px',
    }
    // root: {
    //     width: '100%',
    //     maxWidth: 500,
    // },
    // paper: {
    //     marginBottom: '10px'
    // }
});

const commandsExample = [
    {
        "commandType": "DISARM",
        "createdTime": "2018-09-13T15:24:53.153+12:00",
        "endDate": "2018-09-13T15:30:00+12:00",
        "id": "25a492b6-85d7-4c9b-bfb3-c74021428cd4",
        "processedTime": null,
        "resourceKey": "133d4312-7414-4afa-8814-7d5dddc8bcdd",
        "result": "NOT_PROCESSED",
        "startDate": "2018-09-13T15:00:00+12:00"
    },
    {
        "commandType": "DISARM",
        "createdTime": "2018-09-13T15:24:53.153+12:00",
        "endDate": "2018-09-13T15:30:00+12:00",
        "id": "25685ae7-796a-4dc8-839f-b6b43001a728",
        "processedTime": null,
        "resourceKey": "ae65708a-a3c4-4e47-9575-fc0fb24e36c8",
        "result": "NOT_PROCESSED",
        "startDate": "2018-09-13T15:00:00+12:00"
    },
    {
        "commandType": "DISARM",
        "createdTime": "2018-09-13T15:24:53.153+12:00",
        "endDate": "2018-09-13T15:30:00+12:00",
        "id": "8c407877-9e84-4eef-81cf-0b54dc8e892c",
        "processedTime": null,
        "resourceKey": "bfcba1e3-8b0a-446a-bd8d-f1126a03da86",
        "result": "NOT_PROCESSED",
        "startDate": "2018-09-13T15:00:00+12:00"
    },
    {
        "commandType": "DISARM",
        "createdTime": "2018-09-13T15:24:53.153+12:00",
        "endDate": "2018-09-13T15:30:00+12:00",
        "id": "a8b074dc-807b-4e14-a402-a605aa55b3f5",
        "processedTime": null,
        "resourceKey": "7459486e-8c46-4222-bfa0-61cb41ff6a98",
        "result": "NOT_PROCESSED",
        "startDate": "2018-09-13T15:00:00+12:00"
    }
]

const googleColors = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00", "#b82e2e", "#316395", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac"];


// enum class CommandType {
//     CHARGE, DISCHARGE, SHED, REINSTATE, ARMFIR, ARMFIRSIR, EXTEND, DISARM, HALT, RESET, AUTO, READ, READCONFIG, WRITECONFIG
// }
//
const commandColorMap = {
    "ARMFIR" : "#66aa00",
    "ARMFIRSIR" : "#336600",
    "DISARM" : "#ff9900"
};

const customColorsList = [
    "#1f77b4", "#aec7e8", "#ff7f0e", "#ffbb78", "#2ca02c",
    "#98df8a", "#d62728", "#ff9896", "#9467bd", "#c5b0d5",
    "#8c564b", "#c49c94", "#e377c2", "#f7b6d2", "#7f7f7f",
    "#c7c7c7", "#bcbd22", "#dbdb8d", "#17becf", "#9edae5"
];


class CommandChart extends React.Component {

    eventStyle = (event, state) => {
        const key = event.get("resourceKey");
        const type = event.get("commandType");

        const color = this.state.resourceColors[key];


        switch (type) {
            case "ARMFIR":
                return {
                    fill: color,
                    opacity: 0.7
                };
            case "ARMFIRSIR":
                return {
                    fill: color,
                    opacity: 1.0
                };
            case "DISARM":
                return {
                    fill: color,
                    opacity: 0.3
                };
            default:
        }
    }

    // process the commands
    static getDerivedStateFromProps(nextProps, prevState) {

        console.log("Derived state", nextProps, prevState)

        let noChange = prevState.commands.length === nextProps.commands.length;

        if (noChange) {
            console.log("we have no new commands");
            return prevState
        }

        console.log("We have new commands");

        let commands = nextProps.commands;
        let commandsByKey = groupBy(commands, "resourceKey");


        let resourceColors = {};

        let resourceLegend = nextProps.resources.map(r => ({ key: r.id, label: r.name}));

        nextProps.resources.forEach((r, i) => {
           // resourceColors[r.id] =  customColorsList[i];
           resourceColors[r.id] =  googleColors[i];
        });


        // let rstyler = styler(nextProps.resources.map((r, i) => ({
        //     key: r.id,
        //     color: customColorsList[i]
        // })));

        let rstyler = styler(nextProps.resources.map((r) => ({
            key: r.id,
            color: resourceColors[r.id]
        })));

        // let rstyler = styler(nextProps.resources.map((r) => resourceColors[r] ));



        let seriesList = [];


        for (let [resourceKey, coms] of Object.entries(commandsByKey)) {
            let events = coms.map(({ startDate, endDate, ...data }) =>
                    new TimeRangeEvent(new TimeRange(new Date(startDate), new Date(endDate)), data));
            let series = new TimeSeries({ name: resourceKey, events });
            seriesList.push(series)
        }


        // console.log("We have seriesList", seriesList);
        //
        // const allSeries = TimeSeries.timeSeriesListMerge({
        //     name: "all",
        //     seriesList: seriesList
        // });
        //
        // let timerange = allSeries.timerange();
        // let begin = timerange.begin();
        // let end = timerange.end();

        // timerange.setBegin(begin-1)
        // timerange.setEnd(end+1)
        // timerange = new TimeRange(begin-1, end+1)

        let solStart = new Date(nextProps.solutionStart);
        let solEnd = new Date();
        solEnd.setDate(solStart.getDate() + 3);
        solStart.setDate(solStart.getDate() -1);



        let timerange = new TimeRange(solStart, solEnd);

        return {
            commandsByKey: commandsByKey,
            commands: commands,
            resourceLegend: resourceLegend,
            resourceColors: resourceColors,
            seriesList: seriesList,
            timerange: timerange,
            styler: rstyler
        }


    }

    constructor(props) {
        super(props);
        this.state = {
            tracker: null,
            timerange: null,
            styler: null,
            // timerange: series.timerange()
            // timerange: seriesList[0].timerange()
            commands: [],
            seriesList: []
        };
    }


    // gets implicitly called by the platform
    handleTrackerChanged = (tracker) =>  {
        console.log("tracker", tracker)
        this.setState({ tracker });
    };

    handleTimeRangeChange = (timerange) => {
        console.log("timerange", timerange)
        this.setState({ timerange });
    };

    handleSelectionChange = (e) => {
        // console.log("selection", e);
        console.log("selected", e.get("resourceKey"));
        // this.setState({ selection });
    };

    handleHighlightChange = (resourceId) => {
        // console.log("handleHighlightChange", e);
    }



    render() {

        console.log("state at render is", this.state);
        const classes = this.props.classes

        if (this.state.timerange) {
            return (
                <div className={classes.chartWrapper}>
                    <Legend categories={this.state.resourceLegend} style={this.state.styler}  position="right"
                            // onHighlightChange={this.handleHighlightChange}
                    />

                    <Resizable>
                        <ChartContainer
                            timeRange={this.state.timerange}
                            // trackerPosition={this.state.tracker}
                            // onTrackerChanged={this.handleTrackerChanged}
                            // enablePanZoom={true}
                            enablePanZoom={false}
                            // onTimeRangeChanged={this.handleTimeRangeChange}
                        >
                            { this.state.seriesList.map(s => {
                                return (
                                    <ChartRow height="30" key={uniqueId()}>
                                        <Typography variant="text">Name: {e => e.get("resourceKey")}</Typography>
                                        <Charts>
                                            <EventChart series={s} size={45} label={e => e.get("commandType")} style={this.eventStyle} onSelectionChange={this.handleSelectionChange}/>
                                        </Charts>
                                    </ChartRow>
                                )
                            })}

                        </ChartContainer>
                    </Resizable>
                </div>
            )
        }
        return <div>No timerange, nothing to show</div>
    }
}



CommandChart.propTypes = {
    commands: PropTypes.array.isRequired,
    resources: PropTypes.array.isRequired,
    solutionStart: PropTypes.string.isRequired,
};

export default withStyles(styles)(CommandChart);
