import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import Root from './containers/Root';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router } from 'react-router-dom'
// import configureStore from './redux/configureStore';
import configureStore from './redux/configureStore.dev';
import { CHECK_AUTHENTICATION_TOKEN } from './redux/actions';
import { USER_TOKEN } from "./constants";

// const store = () => {
//     let token = localStorage.getItem(USER_TOKEN);
//
//     let defaultState={};
//     let initialState = token ? { ...defaultState, token: token} : defaultState;
//
//     return configureStore(initialState)
// };

// const user = localStorage.getItem('user');

// console.log('initial state', store.getState())
let defaultState={};
const store = configureStore(defaultState);
// store.dispatch({type: CHECK_AUTHENTICATION_TOKEN})

//
// const authService = new AuthService('http://localhost:8080');
//
// if (authService.loggedIn()) {
//     store.dispatch({ type: AUTHENTICATED });
// } else {
//     store.dispatch({ type: UNAUTHENTICATED});
//     // let token = authService.login("brad", "password").then();
//     // authService.setToken(token) happens inside the login
// }

ReactDOM.render(
    <Root store={store}/>,
    document.getElementById('root'));

registerServiceWorker();
