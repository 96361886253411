import {API_ERROR, API_FETCH, fetchAction, handleErrors} from "./apiActions";
import {snackShowAction} from "./index";
import {SNACK_OPEN} from "./snackActions";
import {FETCH_RESOURCE_BEGIN, FETCH_RESOURCE_FAILURE, FETCH_RESOURCE_SUCCESS} from "./resourceActions";

export const MARKET_LOADED = 'MARKET_LOADED';
export const MARKETS_LOADED  = 'MARKETS_LOADED';
export const MARKETS_LOADING  = 'MARKETS_LOADING';
export const MARKET_SCHEDULES_LOADING = 'MARKET_SCHEDULES_LOADING';
export const MARKET_SCHEDULES_LOADED = 'MARKET_SCHEDULES_LOADED';

export const FETCH_MARKET_SCHEDULES_BEGIN = 'FETCH_MARKET_SCHEDULES_BEGIN';
export const FETCH_MARKET_SCHEDULES_SUCCESS = 'FETCH_MARKET_SCHEDULES_SUCCESS';
export const FETCH_MARKET_SCHEDULES_FAILURE = 'FETCH_MARKET_SCHEDULES_FAILURE';

export const FETCH_MARKET_SUMMARIES_BEGIN = 'FETCH_MARKET_SUMMARIES_BEGIN';
export const FETCH_MARKET_SUMMARIES_SUCCESS = 'FETCH_MARKET_SUMMARIES_SUCCESS';
export const FETCH_MARKET_SUMMARIES_FAILURE = 'FETCH_MARKET_SUMMARIES_FAILURE';

export const getMarketAction = (id) => {
    return async (dispatch) => {
        dispatch({type: MARKETS_LOADING});
        const response = await dispatch(fetchAction(`/api/markets/${id}`, {method: 'GET'}));

        if (response.status === 200) {
            const market = await response.json();
            dispatch({ type: MARKET_LOADED, market: market });

        } else if (response.status === 400) {
            console.log('bad request');
            dispatch({ type: API_ERROR, error: "bad request" });
        } else if (response.status === 404) {
            console.log('not found');
            dispatch({ type: API_ERROR, error: "not found" });
        } else {
            console.error('Unknown state request')
        }
    }
};

export const getMarketsAction = () => {
    return async (dispatch) => {
        dispatch({type: MARKETS_LOADING});
        const result = await dispatch(fetchAction(`/api/markets`, {method: 'GET'}));

        const markets = await result.json();
        dispatch({ type: MARKETS_LOADED, markets: markets.content });
    }
};


export const getMarketSchedulesAction = () => {
    return async (dispatch) => {
        dispatch({type: FETCH_MARKET_SCHEDULES_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/market-schedules?start=2018-06-20T06:30&end=2018-06-20T08:30`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_MARKET_SCHEDULES_SUCCESS, marketSchedules: json.content});
            })
            .catch(error => {
                dispatch({type: FETCH_MARKET_SCHEDULES_FAILURE, error: error});
                dispatch({type: SNACK_OPEN, message: error.message})
            });
    }
};

export const getMarketSummariesAction = () => {
    return async (dispatch) => {
        dispatch({type: FETCH_MARKET_SUMMARIES_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/market-schedules/summaries?start=2018-06-20T06:30&end=2018-06-20T08:30`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_MARKET_SUMMARIES_SUCCESS, marketSummaries: json.content});
            })
            // .catch(error => {
            //     dispatch({type: FETCH_MARKET_SUMMARIES_FAILURE, error: error});
            //     dispatch({type: SNACK_OPEN, message: error.message})
            // });
    }
};
