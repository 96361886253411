import React from 'react';
import {Paper, Typography} from '@material-ui/core';
import SimpleTable from "../mocks/simpleTable";
import GoogleLoginButton from "../containers/GoogleLoginButton";

export const HomePage = () => (
    <div>
        <Paper>

            <SimpleTable/>
        </Paper>




    </div>
);