import { PLAN_SELECTED,
    FETCH_PLAN_BEGIN,
    FETCH_PLAN_SUCCESS,
    FETCH_PLAN_FAILURE,
    FETCH_PLANS_BEGIN,
    FETCH_PLANS_SUCCESS,
    FETCH_PLANS_FAILURE,
    } from '../actions/planActions'

const initialState = {
    records: [],
    selected: null,
    lists: {
        filtered: []
    },
    loading: false,
    error: null
};

    export default function plansReducer(state = initialState, action) {
        switch (action.type) {

            case PLAN_SELECTED:
                return {...state, selected: [...state.records].filter(r => r.id === action.id)[0]};

            case FETCH_PLANS_BEGIN:
                return {...state, isLoading: true, error: null};

            // On success add collection if action.resource is null, otherwise individual object
            case FETCH_PLANS_SUCCESS:
                return {...state, records: action.plans, isLoading: false, error: null};

            // On failure keep the records you already had, display the error
            case FETCH_PLANS_FAILURE:
                return {...state, records: [...state.records], isLoading: false, error: action.error};

            case FETCH_PLAN_BEGIN:
                return {...state, isLoading: true, error: null};

            case FETCH_PLAN_SUCCESS:
                return {...state, records: [...state.records, action.plan], isLoading: false, error: null};

            case FETCH_PLAN_FAILURE:
                return {...state, records: [...state.records], isLoading: false, error: action.error};

            default:
                return state
        }
    }
