import React from 'react';
import {connect} from "react-redux";
import {getSolutionAction, getSolutionsAction} from "../../redux/actions/index";
import {StyledCircularProgress} from "../../components/Progress";
import { withStyles } from '@material-ui/core/styles';
import {ExpansionPanel,ExpansionPanelSummary,ExpansionPanelDetails} from '@material-ui/core';
import {Grid, Paper, Toolbar, Typography, Table, TableBody, TableCell, TableHead, TableRow, List, ListItem, ListItemText } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { StackedBar, Legend } from 'britecharts-react/lib/esm';
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty"
import CommandChart from "../../components/CommandChart";



class SolutionPage extends React.Component {

    componentWillMount() {
        console.log("Solution Page Props", this.props)
        const id  = this.props.match.params.id;
        if(id){
            console.log("Need to load a solution", id)
            this.props.getSolutionAction(id)
        }
    }

    render() {
        const {solution, classes, isLoading} = this.props;

        const solutionIsLoading = isLoading || !solution;

        if (solutionIsLoading) {
            console.log("Solution is loading", solution)
        } else {
            console.log("Load Complete", solution)
        }


        return (
            <Paper>
                <Toolbar>
                    <Typography variant="title">Solution</Typography>
                </Toolbar>
                { solutionIsLoading ? (
                    <StyledCircularProgress />
                ) : (
                    <React.Fragment>
                        <SolutionView solution={solution}/>
                        {/*<SolverChart solution={solution}/>*/}
                        {/*/!*{ !isEmpty(solution.resourceTimeActive) && <SolverChart {...prepSolverForChart(solution)}/> }*!/*/}
                        <Resources resources={solution.resources}/>

                        <CommandsPanel solutionStart={solution.solutionStart}
                                       commands={solution.commands}
                                       resources={solution.resources}/>

                        <Commands commands={solution.commands} resources={solution.resources}/>
                        <Model model={solution.model}/>
                    </React.Fragment>
                )}
            </Paper>
        )
    }
}

SolutionPage.defaultProps = {
    solution: null,
    isLoading: false
}

const CommandsPanel = (props) => (
    <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="title" style={{flexBasis: '33.33%', flexShrink: 0}}>Command Schedule</Typography>
        </ExpansionPanelSummary>

        <CommandChart {...props} />
    </ExpansionPanel>
);


const Event = ({event}) => (
    <List dense={true}>
        <ListItem>
            <ListItemText
                primary="Start Date"
                secondary={event.startDate}/>
        </ListItem>
        <ListItem>
            <ListItemText
                primary="End Date"
                secondary={event.endDate}/>
        </ListItem>
        <ListItem>
            <ListItemText
                primary="Periods"
                secondary={event.periods}/>
        </ListItem>
        <ListItem>
            <ListItemText
                primary="Period length in minutes"
                secondary={event.periodLengthMinutes}/>
        </ListItem>
        {/*<ListItem>*/}
            {/*<ListItemText*/}
                {/*primary="Kw requested"*/}
                {/*secondary={event.kwRequested.join(", ")}/>*/}
        {/*</ListItem>*/}
        {/*<ListItem>*/}
            {/*<ListItemText*/}
                {/*primary="Selector"*/}
                {/*secondary={event.selectorExpression.name}/>*/}
        {/*</ListItem>*/}
    </List>
);

const Solver = ({solver}) => (
    <List dense={true}>
        <ListItem>
            <ListItemText
                primary="Name"
                secondary={solver.name}/>
        </ListItem>
        <ListItem>
            <ListItemText
                primary="Periods"
                secondary={solver.periods}/>
        </ListItem>
        <ListItem>
            <ListItemText
                primary="Period Length in minutes"
                secondary={solver.periodLengthMinutes}/>
        </ListItem>
        <ListItem>
            <ListItemText
                primary="periods"
                secondary={solver.periods}/>
        </ListItem>
        <ListItem>
            <ListItemText
                primary="Kw requested"
                secondary={solver.kw_requested.join(", ")}/>
        </ListItem>
    </List>
)

const Plan = ({plan}) => (
    <List dense={true}>
        <ListItem>
            <ListItemText
                primary="Name"
                secondary={plan.name}/>
        </ListItem>
        <ListItem>
            <ListItemText
                primary="Event Type"
                secondary={plan.eventType}/>
        </ListItem>
        <ListItem>
            <ListItemText
                primary="Selector"
                secondary={plan.selectorExpression.name}/>
        </ListItem>
    </List>
)



const Resources = ({resources}) => (
    <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="title" style={{flexBasis: '33.33%',
                flexShrink: 0}}>Resources</Typography>
            <Typography color="textSecondary" variant="subheading" >
                {resources.length}
            </Typography>

        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>GXP</TableCell>
                        <TableCell>Labels</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resources.map(resource => {
                        return (
                            <TableRow key={resource.id}>
                                <TableCell>{resource.name}</TableCell>
                                <TableCell>{resource.type}</TableCell>
                                {/*<TableCell>{resource.gxp.name}</TableCell>*/}
                                <TableCell>FIXME no gxp</TableCell>
                                <TableCell>{resource.labels.map(l => { return `${l.key}=${l.value}`})}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </ExpansionPanelDetails>
    </ExpansionPanel>
)

const Model = ({model}) => (
    <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="title">Model</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <pre>
        {model}
        </pre>
        </ExpansionPanelDetails>
    </ExpansionPanel>
)

const Commands = ({commands, resources}) => (
    <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="title">Commands</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>id</TableCell>
                        <TableCell>Resource</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell>Command</TableCell>
                        <TableCell>Result</TableCell>
                        <TableCell>ProcessedTime</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {commands.map(command => {
                        const resource = resources.find(resource => resource.id == command.resourceKey)
                        return (
                            <TableRow key={command.id}>
                                <TableCell>{command.id}</TableCell>
                                <TableCell>{resource.name}</TableCell>
                                {/*<TableCell>{command.resourceKey}</TableCell>*/}
                                <TableCell>{command.startDate}</TableCell>
                                <TableCell>{command.endDate}</TableCell>
                                <TableCell>{command.commandType}</TableCell>
                                <TableCell>{command.result}</TableCell>
                                <TableCell>{command.processedTime}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </ExpansionPanelDetails>
    </ExpansionPanel>
)

const SolutionView = ({solution}) => (
    <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant="title">Event - {solution.event.name}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="title">Plan - {solution.plan.name}</Typography>
                </Grid>
                {/*<Grid item xs={4}>*/}
                    {/*<Typography variant="title">Solver - {solution.plan.solver.name}</Typography>*/}
                {/*</Grid>*/}
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Grid container>
                <Grid item xs={4}>
                    <Event event={solution.event}/>
                </Grid>
                <Grid item xs={4}>
                    <Plan plan={solution.plan}/>
                </Grid>
                {/*<Grid item xs={4}>*/}
                    {/*<Solver solver={solution.plan.solver}></Solver>*/}
                {/*</Grid>*/}
            </Grid>
        </ExpansionPanelDetails>
    </ExpansionPanel>
)

// class SolutionDetail extends React.Component {
//
//
// }


const mapStateToProps = (state) => ({
    isLoading: state.solutions.isLoading,
    solution: state.solutions.selected,
})

// const mapDispatchToProps = (dispatch) => {
//     return {
//         loadSolution(id) {
//             dispatch(getSolutionAction(id));
//         },
//     }
// };

// const SolutionDetailsContainer = connect(mapStateToProps, {getSolutionAction})(SolutionDetail);
export default connect(mapStateToProps, {getSolutionAction})(SolutionPage);

// export default SolutionDetailsContainer
// export default withStyles(styles)(SolutionDetailsContainer);
