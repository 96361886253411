import React from 'react';
import {Paper, Typography} from '@material-ui/core';
import { StackedBar, Legend } from 'britecharts-react/lib/esm';
import flatten from 'lodash/flatten';

const data = {
  "event":{
    "name":"KWA0311",
    "period_length_minutes":5,
    "kw_requested":[40,50,60,80,120,130,120,90,60,40,30,20],
    "end_timestamp":"2018-02-03T20:00:00Z",
    "start_timestamp":"2018-02-03T19:00:00Z",
    "periods":12
  },
  "resources":[
    {
      "cost":[1,1,1,1,1,1,1,1,1,1,1,1],
      "id":"Cold",
      "max_kw":[60,60,60,60,60,60,60,60,60,60,60,60],
      "avail":[true,true,true,true,true,true,true,true,true,true,true,true],
      "kwh":30,
      "schedule":[false,false,false,false,true,true,true,true,true,true,false,false]
    },{
      "cost":[3,3,3,3,3,3,3,3,3,3,3,3],
      "id":"Pump",
      "max_kw":[30,30,30,30,30,30,30,30,30,30,30,30],
      "avail":[true,true,true,true,true,true,true,true,true,true,true,true],
      "kwh":960,
      "schedule":[false,false,false,true,false,true,false,true,false,false,true,true]
    },{
      "cost":[2,2,2,2,2,2,2,2,2,2,2,2],
      "id":"Milk",
      "max_kw":[60,60,60,60,60,60,60,60,60,60,60,60],
      "avail":[true,true,true,true,true,true,true,true,true,true,true,true],
      "kwh":120,
      "schedule":[true,true,true,true,true,true,true,false,false,false,false,false]
    },{
      "cost":[4,4,4,4,4,4,4,4,4,4,4,4],
      "id":"Hot",
      "max_kw":[95,95,95,95,95,95,95,95,95,95,95,95],
      "avail":[true,true,true,true,true,true,true,true,true,true,true,true],
      "kwh":95,
      "schedule":[false,false,false,false,false,false,false,false,false,false,false,false]
    }
  ]
}

function resourceFromScheduleToKw(event, resource) {
  let start_timestamp = new Date(event.start_timestamp)
  let end_timestamp = new Date(event.end_timestamp)
  let start_ms = start_timestamp.getTime()
  let end_ms = end_timestamp.getTime()
  let range = end_ms - start_ms
  let period = range / resource.schedule.length

  let results = []
  for (let i = 0; i < resource.schedule.length; i++) {
    results.push({
      name: new Date(start_ms + (period * i)).getHours() + ":" + new Date(start_ms + (period * i)).getMinutes(),
      stack:resource.id,
      value:resource.schedule[i]?resource.max_kw[i]:0
    })
  }
  return results
}

function scheduler(event) { return resource => resourceFromScheduleToKw(event, resource)}

let data2 = flatten(data.resources.map(scheduler(data.event)))
let legendData = data.resources.map(x => ({name:x.id, id:1, quantity:0}))


export const SolverPage = () => (
  <Paper>
      <Typography>SolverPage</Typography>
      <StackedBar
        data={data2}
        width={600}
        height={400}
      />
      <Legend
        data={legendData}
        isHorizontal={true}
        width={600}
        marginRatio={1.8}
        height={100}
      />
  </Paper>
);