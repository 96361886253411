import React from 'react';
import withStyles  from '@material-ui/core/styles/withStyles';
import {CircularProgress} from "@material-ui/core";

const styles = theme => ({
    progressWrapper: {
        flex: 1,
        width: 100,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
});

const Progress50 = (props) => (
    <div className={props.classes.progressWrapper}>
        <CircularProgress className={props.classes.progress} size={50} />
    </div>
);

export const StyledCircularProgress = withStyles(styles)(Progress50);