import {API_ERROR, fetchAction} from "./apiActions";
import {snackShowAction} from "./index";

export const EVENT_SUBMITTING_TO_API = 'EVENT_SUBMITTING_TO_API';
export const EVENT_CREATED = 'EVENT_CREATED';
export const EVENT_LOADED = 'EVENT_LOADED';
export const EVENTS_LOADED  = 'EVENTS_LOADED';
export const EVENTS_LOADING = 'EVENTS_LOADING';
export const EVENT_PUBLISHED = 'EVENT_PUBLISHED';
export const EVENT_TRIGGER = 'EVENT_TRIGGER';

export function triggerEventAction(id) {
    return async function(dispatch) {
        dispatch({type: EVENT_TRIGGER, id: id})

        const response = await dispatch(fetchAction(`/api/events/${id}/trigger`, {method: 'POST'}));

        if (response.status === 200 || response.status === 201) {

            dispatch(snackShowAction(`Event ${id} Triggered`));


        } else if (response.status === 400) {
            dispatch({ type: API_ERROR, error: "bad request" });
        }
    }
}


export function saveEventAction(values) {
    return async function(dispatch){
        dispatch({type: EVENT_SUBMITTING_TO_API, values: values})
        const response = await dispatch(fetchAction(`/api/events`, {method: 'POST'}, values));

        if (response.status === 201) {
            let loc = response.headers.get('Location')
            console.log('status is:', response.status, 'location is:', loc)
            let parts = loc.split('/');
            let id = parts[2]

            const event = await dispatch(getEventAction(id));

            dispatch({ type: EVENT_CREATED, event: event });
            dispatch(snackShowAction("Event Created"));
        } else if (response.status === 400) {
            console.log('bad request')
            dispatch({ type: API_ERROR, error: "bad request" });
        }
    }
};

export const publishEventAction = (event) => {
    return async (dispatch) => {
        dispatch({type: EVENT_PUBLISHED, event: event});
    }
};

export const getEventAction = (id) => {
    return async (dispatch) => {
        dispatch({type: EVENTS_LOADING})
        const response = await dispatch(fetchAction(`/api/events/${id}`, {method: 'GET'}));

        if (response.status === 200) {
            const event = await response.json()
            dispatch({ type: EVENT_LOADED, event: event });

        } else if (response.status === 400) {
            console.log('bad request')
            dispatch({ type: API_ERROR, error: "bad request" });
        } else if (response.status === 404) {
            console.log('not found')
            dispatch({ type: API_ERROR, error: "not found" });
        } else {
            console.error('Unknown state request')
        }
    }
};



export const getEventsAction = () => {
    return async (dispatch) => {
        dispatch({type: EVENTS_LOADING})
        const result = await dispatch(fetchAction(`/api/events`, {method: 'GET'}));

        const events = await result.json()
        dispatch({ type: EVENTS_LOADED, events: events.content });
    }
};
