const defaultConnectionOptions = {
    requestLimit: 3,
    retryDelay: 1000
};

const defaultFetchOptions = {
    method: 'GET'
};

function fetchRetry(url,
                    fetchOptions = defaultFetchOptions,
                    connectionOptions = defaultConnectionOptions) {

    let { requestLimit, retryDelay } = connectionOptions;

    if (requestLimit < 1) {
        throw new Error('Invalid arguments: requestLimit should be at least 1.');
    }
    if (retryDelay < 1) {
        throw new Error('Invalid arguments: delay should be at least 1.');
    }

    function callFetch() {
        return fetch(url, fetchOptions);
    }

    if (requestLimit === 1) {
        console.log("fetch-retry single call");
        return callFetch();
    }

    return new Promise(function (resolve, reject) {
        function success(response) {
            console.log("fetch-retry success");
            resolve(response);
        }
        function failure(error) {
            console.log("fetch-retry failure");
            requestLimit--;
            if (requestLimit) {
                setTimeout(callFetchAgain, retryDelay);
            } else {
                reject(error);
            }
        }
        function finalFailure(finalError) {
            console.error("fetch-retry final failure");
            reject(finalError);
        }
        function callFetchAgain() {
            return callFetch().then(success).catch(failure).catch(finalFailure);
        }
        callFetchAgain();
    });
};

export default fetchRetry;