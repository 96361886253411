import React from 'react';
import noAuth from "./components/hoc/noAuth";
import withAuth from "./components/hoc/withAuth";
import Pages from "./pages";

import {Route, Switch} from "react-router-dom";

export const contentPaneRoutes = (
    <Switch>
        <Route exact path="/"       component={Pages.HomePage} />
        <Route exact path="/grid-events"       component={Pages.GridEventsPage} />
        <Route path="/grid-events/:id"       component={Pages.GridEventPage} />
        <Route exact path="/plans"        component={Pages.PlansPage} />
        <Route path="/plans/:id"        component={Pages.PlanPage} />
        <Route exact path="/solutions"        component={Pages.SolutionsPage} />
        <Route path="/solutions/:id"        component={Pages.SolutionPage} />
        <Route exact path="/resources"    component={Pages.ResourcesPage} />
        <Route path="/resources/:id/schedule"    component={Pages.ResourceSchedulePage} />
        <Route path="/resources/:id/readings"    component={Pages.ResourceReadingsPage} />
        <Route path="/resources/:id"    component={Pages.ResourcePage} />

        <Route exact path="/solver"    component={Pages.SolverPage} />
        <Route exact path="/developers"    component={Pages.DevelopersPage} />
        <Route exact path="/markets"    component={Pages.MarketsPage} />
        <Route path="/markets/:id"    component={Pages.MarketPage} />
        <Route exact path="/market-schedules"    component={Pages.MarketSchedulesPage} />
        <Route exact path="/charts"    component={Pages.ChartsPage} />
        <Route exact path="/readings"     component={withAuth(Pages.ReadingsPage)} />
        <Route exact path="/harness"      component={noAuth(Pages.HarnessPage)} />
        {/*<Route path="/loginharness"       component={Pages.LoginHarnessPage} />*/}
        {/*<Route path="/login"       component={Pages.LoginPage} />*/}
        <Route component={Pages.NotFoundPage} />
    </Switch>
)