import {
    FETCH_SOLUTION_BEGIN,
    FETCH_SOLUTION_SUCCESS,
    FETCH_SOLUTION_FAILURE,
    FETCH_SOLUTIONS_BEGIN,
    FETCH_SOLUTIONS_SUCCESS,
    FETCH_SOLUTIONS_FAILURE,
    SOLUTION_SELECTED,
} from '../actions/solutionActions'
import {FETCH_RESOURCE_BEGIN, RESOURCE_SELECTED} from "../actions/resourceActions";

const initialState = {
    records: [],
    selected: null,
    lists: {
        filtered: []
    },
    loading: false,
    error: null
};

export default function solutionsReducer(state = initialState, action) {
    switch(action.type) {

        case SOLUTION_SELECTED:
            return { ...state, selected: [...state.records].filter(r => r.id === action.id)[0] };

        case FETCH_SOLUTION_BEGIN:
            return { ...state, selected: null, isLoading: true, error: null };

        case FETCH_SOLUTION_SUCCESS:
            return { ...state, records: [...state.records, action.solution], isLoading: false };

        case FETCH_SOLUTIONS_SUCCESS:
            return { ...state, records: action.solutions, isLoading: false };

        case FETCH_SOLUTION_FAILURE:
            return { ...state, records: [...state.records], isLoading: false, error: action.error };

        case FETCH_SOLUTIONS_FAILURE:
            return { ...state, records: [...state.records], isLoading: false, error: action.error };

        default:
            return state
    }
}
