import * as CONSTANTS from '../../constants';

export const AUTHENTICATED = 'AUTHENTICATED';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const CHECK_AUTHENTICATION_TOKEN = 'CHECK_AUTHENTICATION_TOKEN';

export const LOGOUT = 'LOGOUT';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

//
const URL = 'http://www.sample-website.com';
// const T


export function checkAuthenticationToken(dispatch) {
    console.log('checkAuthenticationToken action');

    dispatch({type: CHECK_AUTHENTICATION_TOKEN});
    let token = localStorage.getItem(CONSTANTS.USER_ACCESS_TOKEN);
    try {
        let jsonToken = JSON.parse(token);
        console.log('json is', jsonToken)



// Retrieve the object from storage
//     var retrievedObject = localStorage.getItem('testObject');

        // console.log('retrievedObject: ', );
        console.log('token is', token)
        if (token === null) {
            return dispatch({ type: UNAUTHENTICATED });
        }
        // check expiry
        console.log('expiry is', jsonToken.expires_at)
        // console.log('now is', now, 'token is good for', jsonToken.expires_at - now)


        if (jsonToken.expires_at - (new Date()).getTime() > 0) {

            // Access token is valid so read the user details which are currently in storage
            let profile = JSON.parse(localStorage.getItem(CONSTANTS.USER_PROFILE_OBJECT));

            return dispatch({ type: AUTHENTICATED, profile: profile, accessToken: jsonToken });
        } else {

            // FIXME.  Try to get a new google access token based on the data we already have
            return dispatch({ type: UNAUTHENTICATED });
        }

    } catch (error) {
        console.error(error)
    }


    // return async (dispatch) => {
    //
    //
    //     await dispatch({type: CHECK_AUTHENTICATION_TOKEN});
    //
    //     let token = localStorage.getItem(CONSTANTS.USER_TOKEN);
    //
    //     console.log('token is', token)
    //     if (token === null) {
    //         return dispatch({ type: UNAUTHENTICATED });
    //     }
    //
    //     //check expiry
    //     console.log('expiry is', token)
    //
    // }
}

export function signInAction({ email, password }, history) {
    return async (dispatch) => {
        try {
            // const res = await fetch`${URL}/signin`, { email, password });

            let res = {data: {token: 'blah'}}

            dispatch({ type: AUTHENTICATED });
            localStorage.setItem('user', res.data.token);
            history.push('/secret');
        } catch(error) {
            dispatch({
                type: AUTHENTICATION_ERROR,
                payload: 'Invalid email or password'
            });
        }
    };
}

// https://medium.com/@jacobp100/you-arent-using-redux-middleware-enough-94ffe991e6
// Trick to compare things
// const previousToken = store.getState().token;
// next(action);
// const nextToken = store.getState().token;

// Respond to changes
//if (nextToken !== previousToken) localStorage.setItem('token', nextToken);









export function signOutAction() {
    localStorage.clear();
    return {
        type: UNAUTHENTICATED
    };
}


export const googleAuthSuccessAction = (user) => {
    localStorage.setItem(CONSTANTS.USER_JWT, user.tokenId);
    localStorage.setItem(CONSTANTS.USER_PROFILE_OBJECT, JSON.stringify(user.profileObj));
    // localStorage.setItem(CONSTANTS.USER_TOKEN, response.user.tokenObj);
    localStorage.setItem(CONSTANTS.USER_ACCESS_TOKEN, JSON.stringify(user.tokenObj));
    // Put the object into storage
    // localStorage.setItem('testObject', JSON.stringify(testObject));
    console.log('success', user);
    return {
        type: AUTHENTICATED, accessToken: user.tokenObj, profile: user.profileObj
    };

}

export const googleAuthFailureAction = (response) => {
    console.log('Google Failed', response);
    return {
        type: UNAUTHENTICATED
    };
}





