import React, {Component} from 'react';
import {Button, Paper, Toolbar, Typography} from '@material-ui/core';
import {connect} from "react-redux";
import {getResourcesAction, snackShowAction} from "../../redux/actions/index";
import {StyledCircularProgress} from "../../components/Progress";
import showResults from "../../mocks/showResults";
import ResourceForm from "../../components/ResourceForm";
import ResourcesTable from "../../components/ResourcesTable";

import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';

import { withRouter } from 'react-router-dom'
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    root: {
        // width: '100%',
        // maxWidth: 500,

    },

    button: {
        margin: theme.spacing.unit,
    },
    buttons: {
        //align: left,
        // flexGrow: 1
    },
    toolbar: {
        // flexGrow: 1
    },
    pageTitle: {
        flexGrow: 1
    },
    paper: {
        marginBottom: '10px'
    }
});

class ResourcesPage extends Component {

    state = {
        formOpen: false,
    };

    handleFormOpen = () => {
        this.setState({ formOpen: true });
    };

    handleFormClose = (event, reason) => {
        this.setState({ formOpen: false });
    };

    componentDidMount() {
        this.props.getResourcesAction()
    }

    render() {
        const {isLoading, resources, classes} = this.props;
        const {formOpen } = this.state;

        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="title" className={classes.pageTitle}>Resources</Typography>

                        <div className={classes.buttons}>
                            <Button variant="contained" onClick={this.handleFormOpen} className={classes.button}>
                                <AddIcon />Add
                            </Button>
                            <Button variant="contained" className={classes.button}>
                                <DeleteIcon />Delete
                            </Button>

                        </div>

                    </Toolbar>
                </Paper>

                <Paper className={classes.paper}>
                    { formOpen && <ResourceForm  onSubmit={showResults} handleClose={this.handleFormClose}/> }
                </Paper>

                <Paper className={classes.paper}>
                    { isLoading ? (
                        <StyledCircularProgress />
                    ) : (
                            <ResourcesTable resources={resources} />
                    )}
                </Paper>


            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.resources.isLoading,
    resources: state.resources.records,
});

// Enable ability to view where we at
ResourcesPage = withRouter(ResourcesPage);
ResourcesPage = connect(mapStateToProps, {
    getResourcesAction,
    snackShowAction
})(ResourcesPage);

export default withStyles(styles)(ResourcesPage);