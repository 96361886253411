import React from 'react';
import PropTypes from 'prop-types';
import withStyles  from '@material-ui/core/styles/withStyles';
import {Toolbar, Typography, Paper, TableBody, TableCell, TableHead, TableRow, Table, Button} from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

function EventList({events, onClickHandler, classes}) {

    return (
        <Paper className={classes.root}>
            <Toolbar>
                <Typography variant="title">Events</Typography>
            </Toolbar>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Periods</TableCell>
                        <TableCell>Period Length</TableCell>
                        <TableCell>KW Requested</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {events.map(e =>
                        <TableRow key={e.id}>
                            <TableCell>{e.id}</TableCell>
                            <TableCell>{e.type}</TableCell>
                            <TableCell>{e.startDate}</TableCell>
                            <TableCell>{e.endDate}</TableCell>
                            <TableCell>{e.periods}</TableCell>
                            <TableCell>{e.periodLengthMinutes}</TableCell>
                            <TableCell>{e.kwRequested}</TableCell>
                            <TableCell>
                                <Button color="primary" onClick={() => onClickHandler(e.id)}>Trigger</Button>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Paper>
    );
}

EventList.propTypes = {
    classes: PropTypes.object.isRequired,
    events: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            // type: PropTypes.string.isRequired,
            type: PropTypes.string,
            startDate: PropTypes.string.isRequired,
            endDate: PropTypes.string.isRequired,
            kwRequested: PropTypes.array.isRequired,
            periodLengthMinutes: PropTypes.number.isRequired,
            periods: PropTypes.number.isRequired,
        })
    ).isRequired
};

export default withStyles(styles)(EventList);