import { combineReducers } from 'redux';
import { reducer as formReducer} from "redux-form";
import eventsReducer from './eventsReducer';
import gridEventsReducer from './gridEventsReducer';
import resourcesReducer from './resourcesReducer';
import authReducer from './authReducer';
import messagesReducer from './messagesReducer';
import solutionsReducer from './solutionsReducer';
import plansReducer from './plansReducer';
import snacksReducer from './snacksReducer';
import marketsReducer from './marketsReducer';
import marketsSchedulesReducer from './marketSchedulesReducer';
import resourceSchedulesReducer from './resourceSchedulesReducer';
import marketSummariesReducer from './marketSummariesReducer';
import resourceReadingsReducer from './resourceReadingsReducer';

const rootReducer = combineReducers({
    form: formReducer,
    auth: authReducer,
    events: eventsReducer,
    resources: resourcesReducer,
    gridEvents: gridEventsReducer,
    plans: plansReducer,
    solutions: solutionsReducer,
    messages: messagesReducer,
    snacks: snacksReducer,
    markets: marketsReducer,
    marketSchedules: marketsSchedulesReducer,
    resourceSchedules: resourceSchedulesReducer,
    marketSummaries: marketSummariesReducer,
    readings: resourceReadingsReducer,
});

export default rootReducer