import React from 'react';
import {Paper, Typography} from '@material-ui/core';

export const ReadingsPage = () => (
    <div>
        <Paper>
            <Typography>Readings</Typography>
        </Paper>



    </div>
);