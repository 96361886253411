import React, {Component} from 'react';
import {Paper, TableCell, TableRow, Toolbar, Typography} from '@material-ui/core';
import {connect} from "react-redux";
import {
    snackShowAction,
    getResourceAction,
    getResourceReadingsAction} from "../../redux/actions";
import {StyledCircularProgress} from "../../components/Progress";
import ResourceReadingsTable from "../../components/ResourceReadingsTable";
import {Link, withRouter, Navlink} from 'react-router-dom'
import withStyles from "@material-ui/core/styles/withStyles";
import {shortId} from "../../util/functions";

const styles = theme => ({
    root: {
    },
    button: {
        margin: theme.spacing.unit,
    },
    pageTitle: {
        flexGrow: 1
    },
    paper: {
        marginBottom: '10px'
    }
});

class ResourceReadingsPage extends Component {

    componentWillMount() {
        const id = this.props.match.params.id;
        if (id) {
            this.props.getResourceAction(id);
            this.props.getResourceReadingsAction(id);
        }
    }

    render() {
        const {readingsLoading, resourceLoading, resource, readings, classes} = this.props;
        let loaded = !readingsLoading && !resourceLoading && resource;

        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Toolbar className={classes.toolbar}>
                        { loaded &&
                        <Typography variant="title" className={classes.pageTitle}>Resource Readings
                            for <Link to={`/resources/${resource.id}`}>{resource.name} {shortId(resource.id)}</Link>
                        </Typography>
                        }
                    </Toolbar>
                </Paper>

                <Paper className={classes.paper}>
                    { !loaded ? (
                        <StyledCircularProgress />
                    ) : (
                        <ResourceReadingsTable readings={readings} />
                    )}
                </Paper>


            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    readingsLoading: state.readings.isLoading,
    resourceLoading: state.resources.isLoading,
    readings: state.readings.records,
    resource: state.resources.selected,
});

// Enable ability to view where we at
ResourceReadingsPage = withRouter(ResourceReadingsPage);
ResourceReadingsPage = connect(mapStateToProps, {
    getResourceReadingsAction,
    getResourceAction,
    snackShowAction
})(ResourceReadingsPage);

export default withStyles(styles)(ResourceReadingsPage);