import {API_FETCH, API_ERROR, fetchAction, handleErrors, extractIdFromHeader} from "./apiActions";
import {snackShowAction} from "./index";

import fetchRetry from "../../util/fetch-retry"
import {SNACK_OPEN} from "./snackActions";


export const GRID_EVENT_SELECTED = 'GRID_EVENT_SELECTED';

export const FETCH_GRID_EVENT_BEGIN = 'FETCH_GRID_EVENT_BEGIN';
export const FETCH_GRID_EVENT_SUCCESS = 'FETCH_GRID_EVENT_SUCCESS';
export const FETCH_GRID_EVENT_FAILURE = 'FETCH_GRID_EVENT_FAILURE';

export const FETCH_GRID_EVENTS_BEGIN = 'FETCH_GRID_EVENTS_BEGIN';
export const FETCH_GRID_EVENTS_SUCCESS = 'FETCH_GRID_EVENTS_SUCCESS';
export const FETCH_GRID_EVENTS_FAILURE = 'FETCH_GRID_EVENTS_FAILURE';



export const getGridEventAction = (id) => {
    return async (dispatch) => {
        dispatch({type: FETCH_GRID_EVENT_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/grid-events/${id}`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_GRID_EVENT_SUCCESS, gridEvent: json});
                dispatch({type: GRID_EVENT_SELECTED, id: id});
            })
            .catch(error => {
                dispatch({type: FETCH_GRID_EVENT_FAILURE, error: error});
                dispatch({type: SNACK_OPEN, message: error.message})
            });
    }
};


export const getGridEventsAction = () => {
    return async (dispatch) => {
        dispatch({type: FETCH_GRID_EVENTS_BEGIN });
        await dispatch({type: API_FETCH, url: `/api/grid-events`})
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: FETCH_GRID_EVENTS_SUCCESS, gridEvents: json.content});
            })
            .catch(error => {
                dispatch({type: FETCH_GRID_EVENTS_FAILURE, error: error});
                dispatch({type: SNACK_OPEN, message: error.message})
            });
    }
};
