import { AUTHENTICATED, UNAUTHENTICATED, AUTHENTICATION_ERROR, CHECK_AUTHENTICATION_TOKEN } from '../actions';

const DEFAULT_STATE = {
    profile: {},
    accessToken: null,
    authenticated: false
};

export default function(state=DEFAULT_STATE, action) {
    switch(action.type) {
        case AUTHENTICATED:
            return {...state, profile: action.profile, accessToken: action.accessToken, authenticated: true};
        case UNAUTHENTICATED:
            return DEFAULT_STATE;
        case AUTHENTICATION_ERROR:
            return {...state, error: action.payload};
        case CHECK_AUTHENTICATION_TOKEN:
            return {...state, token: action.payload};
        default:
            return state
    }
}