import React from 'react';
import {Paper, Typography} from '@material-ui/core';
import {
    Charts,
    ChartContainer,
    ChartRow,
    YAxis,
    LineChart,
    Resizable,
    EventChart,
    styler
} from "react-timeseries-charts";
import { TimeSeries, TimeRange, TimeRangeEvent } from "pondjs";

//
// Test data
//



const outageEvents = [
    {
        startTime: "2015-03-08T09:00:00Z",
        endTime: "2015-03-22T14:00:00Z",
        title: "ANL Scheduled Maintenance",
        description: "ANL will be switching border routers...",
        completed: true,
        external_ticket: "",
        esnet_ticket: "ESNET-20150302-002",
        organization: "ANL",
        type: "Planned"
    },
    {
        startTime: "2015-04-01T03:30:00Z",
        endTime: "2015-04-02T16:50:00Z",
        title: "STAR-CR5 < 100 ge 06519 > ANL  - Outage",
        description: "The listed circuit was unavailable due to bent pins.",
        completed: true,
        external_ticket: "3576:144",
        esnet_ticket: "ESNET-20150421-013",
        organization: "Internet2 / Level 3",
        type: "Unplanned"
    },
    {
        startTime: "2015-04-22T03:30:00Z",
        endTime: "2015-04-22T13:00:00Z",
        description: "At 13:33 pacific circuit 06519 went down.",
        title: "STAR-CR5 < 100 ge 06519 > ANL  - Outage",
        completed: true,
        external_ticket: "",
        esnet_ticket: "ESNET-20150421-013",
        organization: "Internet2 / Level 3",
        type: "Unplanned"
    }
];

const data = {
    name: "traffic",
    columns: ["time", "in", "out"],
    points: [
        [1400425947000, 52, 41],
        [1400425948000, 18, 45],
        [1400425949000, 26, 49],
        [1400425950000, 93, 81],
    ]
};

const trafficSeries = new TimeSeries(data);

//
// Turn data into TimeSeries
//

const events = outageEvents.map(
    ({ startTime, endTime, ...data }) =>
        new TimeRangeEvent(new TimeRange(new Date(startTime), new Date(endTime)), data)
);
const series = new TimeSeries({ name: "outages", events });




//
// Render event chart
//

function outageEventStyleFunc(event, state) {
    const color = event.get("type") === "Planned" ? "#998ec3" : "#f1a340";
    switch (state) {
        case "normal":
            return {
                fill: color
            };
        case "hover":
            return {
                fill: color,
                opacity: 0.4
            };
        case "selected":
            return {
                fill: color
            };
        default:
        //pass
    }
}

class ChartPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tracker: null,
            timerange: series.timerange()
        };
    }

    // gets implicitly called by the platform
    handleTrackerChanged = (tracker) =>  {
        console.log("tracker", tracker)
        this.setState({ tracker });
    }

    handleTimeRangeChange = (timerange) => {
        console.log("timerange", timerange)
        this.setState({ timerange });
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <Resizable>
                            <ChartContainer
                                timeRange={this.state.timerange}
                                enablePanZoom={true}
                                onTimeRangeChanged={this.handleTimeRangeChange}
                            >
                                <ChartRow height="30">
                                    <Charts>
                                        <EventChart
                                            series={series}
                                            size={45}
                                            style={outageEventStyleFunc}
                                            label={e => e.get("title")}
                                        />


                                    </Charts>
                                </ChartRow>
                                <ChartRow height="200">
                                {/*<YAxis id="axis1" label="AUD" min={0.5} max={1.5} width="60" type="linear" format="$,.2f"/>*/}
                                <Charts>
                                    <LineChart axis="axis1" series={trafficSeries}/>
                                    {/*<LineChart axis="axis2" series={series2}/>*/}
                                </Charts>
                                {/*<YAxis id="axis2" label="Euro" min={0.5} max={1.5} width="80" type="linear" format="$,.2f"/>*/}
                            </ChartRow>
                            </ChartContainer>
                        </Resizable>
                    </div>
                </div>
            </div>
        );
    }
}


// const ChartPage = () => (
//     <Paper>
//         <Typography>ChartPage</Typography>
//
//
//
//         <ChartContainer
//             timeRange={this.state.timerange}
//             enablePanZoom={true}
//             onTimeRangeChanged={this.handleTimeRangeChange}>
//             <ChartRow height="35">
//                 <Charts>
//                     <EventChart
//                         series={series}
//                         style={(outageEventStyleCB)}
//                         label={e => e.get("title")} />
//                 </Charts>
//             </ChartRow>
//         </ChartContainer>
//
//     </Paper>
// );


export default ChartPage
