import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Table, { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core/Table';
import {Toolbar, Typography, Paper, TableBody, TableCell, TableHead, TableRow, Table} from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

let id = 0;
function createData(name, type, demand, duration, location) {
    id += 1;
    return { id, name, type, demand, duration, location };
}



const data = [
    createData('Transpower DR 3', 'DR', 12.0, 240, 'Palmerston North'),
    createData('Frequency at GXP-ABC-123', 'FREQ', 150., 2, 'Christchurch'),
    createData('Frequency at Frankton', 'FREQ', 40, 30, 'Frankton'),
];

function SimpleTable(props) {
    const { classes } = props;

    return (
        <Paper className={classes.root}>
            <Toolbar>
                <Typography variant="title">DR Events</Typography>
            </Toolbar>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell numeric>Demand (MW)</TableCell>
                        <TableCell numeric>Duration (mins)</TableCell>
                        <TableCell>Location</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(n => {
                        return (
                            <TableRow key={n.id}>
                                <TableCell>{n.name}</TableCell>
                                <TableCell>{n.type}</TableCell>
                                <TableCell numeric>{n.demand}</TableCell>
                                <TableCell numeric>{n.duration}</TableCell>
                                <TableCell>{n.location}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Paper>
    );
}

SimpleTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);