import React, {Component} from 'react';
import MockPlansTable from '../../mocks/plansTable'

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    withStyles
} from '@material-ui/core';
import {connect} from "react-redux";
import {getPlansAction} from "../../redux/actions/index";
import {StyledCircularProgress} from "../../components/Progress";
import {shortId} from "../../util/functions";
import {Link} from "react-router-dom";


class PlansPage extends Component {

    componentWillMount() {
        this.props.getPlansAction()
    }

    render() {

        const {isLoading, plans} = this.props;

        return isLoading? <StyledCircularProgress /> : <PlansList {...this.props}/>

    }
}

export const PlansList = ({plans}) => (
    <Paper>
        <Toolbar>
            <Typography variant="title">Plans</Typography>
        </Toolbar>

        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Event Type</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {plans.map(p => {
                    return (
                        <TableRow key={p.id}>
                            <TableCell><Link to={`/plans/${p.id}`}>{shortId(p.id)}</Link></TableCell>
                            <TableCell>{p.name}</TableCell>
                            <TableCell>{p.eventType}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </Paper>
);


const mapStateToProps = (state) => ({
    isLoading: state.plans.isLoading,
    plans: state.plans.records,
})



PlansPage = connect(mapStateToProps, {getPlansAction})(PlansPage);
export default PlansPage