import React, { Component } from 'react';
import {Paper, Typography, withStyles} from '@material-ui/core';
import {connect} from "react-redux";
import {getEventAction} from "../redux/actions";
import {StyledCircularProgress} from "../components/Progress";



export const EventPage = () => (
    <div>
        <Paper>
            <Typography>Single Event</Typography>
        </Paper>
    </div>
);




class EventDetails extends Component {

    componentDidMount() {
        this.props.loadEvent()
    }

    render() {
        const event = this.props.events.selected;

        return this.props.isLoading
            ? <StyledCircularProgress /> :
            <div>{event.message}</div>
    }
}


const mapStateToProps = (state) => ({
    isLoading: state.events.isLoading,
    event: state.event,
})

const mapDispatchToProps = (dispatch) => {
    return {
        loadEvent() {
            dispatch(getEventAction());
        },
    }
};

const EventDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(EventDetails);