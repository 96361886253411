import React, { Component } from 'react';
import { connect } from 'react-redux';
import {AppBar, Avatar, Button, Toolbar, Typography} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import GoogleLoginButton from "./GoogleLoginButton";
import PropTypes from "prop-types";
import {signOutAction} from "../redux/actions";


const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft: drawerWidth,
    },
    appBarLeftArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        // flexGrow: 1
    },
    avatar: {
        marginRight: 20,
        // width: 60,
        // height: 60,
    },
    loginButton: {
    },
    logoutButton: {
        color: 'primary'
    },
    profileText: {
        marginRight: 20,
    },
    toolbarAlign: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        // flexDirection: 'row',
        // flexWrap: 'nowrap',
        justifyContent: 'space-between'
    },

});


class AppBarTop extends Component {

    render() {
        const classes = this.props.classes;
        const authenticated = this.props.auth.authenticated;
        const profile = this.props.auth.profile;
        // const logout = this.props.logout;

        // const message =  (
        //     authenticated ?
        //         <div>Logged in</div>
        //         :
        //         <div>Not logged in</div>
        // );

        return (
        <AppBar position="absolute" className={classes.appBar}>
            <Toolbar>
                <div className={classes.toolbarAlign}>
                    <Typography variant="title" color="inherit" noWrap>
                        EnergyNet
                    </Typography>
                    {authenticated ?
                        <div className={classes.appBarLeftArea}>
                            <Typography color="inherit" noWrap className={classes.profileText}>Welcome {profile.name}</Typography>
                            <Avatar
                                alt={profile.name}
                                src={profile.imageUrl}
                                className={classNames(classes.avatar)}
                            />
                            <LogoutButton className={classes.logoutButton} onClick={this.props.logout} />
                        </div>
                        :
                        <div className={classes.appBarLeftArea}>
                            <GoogleLoginButton/>
                        </div>
                    }
                </div>
            </Toolbar>
        </AppBar>
        )
    }
}



// const LoginButton = (props) => (
//
//     <div>
//         <Button variant="raised" color="primary" className={props.classes}>
//             Login
//         </Button>
//     </div>
// );

// LoginButton.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

const LogoutButton = (props) => (
    <div>
        <Button variant="raised" color="primary" className={props.classes} onClick={props.onClick}>
            Logout
        </Button>
    </div>
);

// const signOutDispatch = (dispatch) => {
//     return {
//         logout() {
//             console.log('logout invoked')
//             dispatch(signOutAction());
//         },
//     }
// };

// const ConnectedLogoutButton = connect({}, signOutDispatch)(LogoutButton)

LogoutButton.propTypes = {
    // classes: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};


// export default withStyles(styles)(LoginButton)

let styledTopBar = withStyles(styles)(AppBarTop);

// Redux Work
const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        logout() {
            dispatch(signOutAction());
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(styledTopBar)

