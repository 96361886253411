import React, { Component } from 'react';
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {TextField, Button, Grid, MenuItem, Select, FormControl, InputLabel, FormHelperText, Input} from "@material-ui/core";
import withStyles  from '@material-ui/core/styles/withStyles';
import { saveResourceAction } from '../redux/actions/index'
import { renderTextField, renderSelectField } from './FormHelpers'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    menu: {
        width: 200,
    },
    form: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit
    }
});

const validate = values => {
    const errors = {}
    if (!values.firstName) {
        errors.firstName = 'Error: Required'
    } else if (values.firstName != 'Brad') {
        errors.firstName = 'Error: You are not brad'
    }
    return errors
};



class ResourceForm extends Component {

    constructor(props) {
        super(props)

    }

    state = {
        name: '',
        capacity: 0,
        icp: '',
        owner: '',
        network: '',
        island: '',
        gxp: '',
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    render() {
        const { classes, pristine, reset, submitting, handleSubmit, handleClose } = this.props;

        const IslandField = () => (
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="island">Island</InputLabel>
                <Field
                    native
                    name="island"
                    value={this.state.island}
                    onChange={this.handleChange('island')}
                    component={renderSelectField}>
                    <option />
                    <option value="N">North</option>
                    <option value="S">South</option>
                </Field>
            </FormControl>
        );

        const NetworkField = () => (
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="network">Network</InputLabel>
                <Field
                    native
                    name="network"
                    value={this.state.network}
                    onChange={this.handleChange('network')}
                    component={renderSelectField}>
                    <option />
                    <option value="WLG">Wellington Electricity</option>
                    <option value="ORON">Orion</option>
                    <option value="POCO">PowerCo</option>
                </Field>
            </FormControl>
        );
        return (

            <form onSubmit={handleSubmit(this.props.saveResource)} className={classes.form}>
                <h1>ResourceForm Here</h1>

                <Grid container className={classes.root} spacing={16}>
                    <Grid item sm={8}>
                        <Field name="name" component={renderTextField} label="Name" help="Name of the resource?"
                            required classes={classes} />
                        <Field name="capacity" component={renderTextField} label="Capacity"
                               classes={classes} />
                        <Field name="icp" component={renderTextField} label="ICP"
                                   classes={classes} />
                        <Field name="owner" component={renderTextField} label="Owner"
                                   classes={classes} />

                    </Grid>
                    <Grid item sm={8}>
                        <IslandField/>
                        <NetworkField/>
                        <Field name="gxp" component={renderTextField} label="GXP"
                               classes={classes} />
                    </Grid>
                    <Grid item sm={8}>
                        <div id="actions">
                            <Button variant="raised" color="primary" type="submit" className={classes.button} disabled={pristine || submitting}>
                                Create Resource
                            </Button>
                            <Button variant="raised" type="button" className={classes.button} disabled={pristine || submitting} onClick={reset}>
                                Reset
                            </Button>
                            <Button className={classes.button} disabled={submitting} onClick={handleClose}>
                                Cancel
                            </Button>

                        </div>
                    </Grid>
                </Grid>
            </form>



        )
    }
};

const mapStateToProps = (state) => ({
    // If we have a resource in focus put the properties here
    // ...
});

const mapDispatchToProps = (dispatch)  => {
    return {
        saveResource(values) { dispatch(saveResourceAction(values))}
    }
};

ResourceForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceForm);

ResourceForm = withStyles(styles)(ResourceForm);

export default reduxForm({
    form: 'second', // a unique name for this form
    validate
})(ResourceForm);