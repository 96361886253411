import React from 'react';
import PropTypes from 'prop-types';
import {TableBody, TableCell, TableHead, TableRow, Table } from "@material-ui/core";
import {Link} from "react-router-dom";
import split from 'lodash/split';
import {FormattedDateTime} from "./FormattedDateTime";

/*
 startAt = rsi.startAt,
endAt = rsi.endAt,
resourceId = rsi.resource?.id,
marketId = rsi.market?.id,
market = rsi.market?.name,
solutionId = rsi.solution?.id,
scheduleType = rsi.scheduleType

 */
function ResourceSchedulesTable({resourceSchedules}) {

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Start At</TableCell>
                    <TableCell>End At</TableCell>
                    <TableCell>Market</TableCell>
                    <TableCell>Solution</TableCell>
                    <TableCell>Schedule Type</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {resourceSchedules.map(s => {
                    let shortId = split(s.id,'-')[0];
                    let marketId = split(s.marketId,'-')[0];
                    let solutionId = split(s.solutionId,'-')[0];
                    return (
                        <TableRow key={s.id}>
                            <TableCell>{shortId}</TableCell>
                            <TableCell><FormattedDateTime value={s.startAt}/></TableCell>
                            <TableCell><FormattedDateTime value={s.endAt}/></TableCell>
                            <TableCell><Link to={`/markets/${s.marketId}`}>{marketId}</Link></TableCell>
                            <TableCell><Link to={`/solutions/${s.solutionId}`}>{solutionId}</Link></TableCell>
                            <TableCell>{s.scheduleType}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

export default ResourceSchedulesTable;