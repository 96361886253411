import {
    FETCH_MARKET_SCHEDULES_SUCCESS,
    MARKET_SCHEDULES_LOADED,
    MARKET_SCHEDULES_LOADING
} from '../actions/marketActions'

export default function marketsReducer(state={records: [], isLoading: false}, action) {
    switch(action.type) {

        case MARKET_SCHEDULES_LOADING:
            return { ...state, isLoading: true };

        case MARKET_SCHEDULES_LOADED:
            return { ...state, records: action.marketSchedules, isLoading: false };

        case FETCH_MARKET_SCHEDULES_SUCCESS:
            return { ...state, records: action.marketSchedules, isLoading: false };

        default:
            return state
    }
}
