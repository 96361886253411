// Make fetch into an action
import {API_HOST, APPLICATION_JSON_UTF8, AUTHORIZATION, USER_TOKEN, USER_JWT} from '../constants'
import {API_ERROR, API_FETCH} from "../redux/actions/apiActions";

import fetchRetry from './fetch-retry'
import endsWith from 'lodash/endsWith'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'

const BEGIN = 'BEGIN';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const CANCEL = 'CANCEL';

// https://stackoverflow.com/questions/46946380/fetch-api-request-timeout


const defaultFetchOptions = {
    method: 'GET',
    headers: new Headers({
        'Accept': APPLICATION_JSON_UTF8,
        'Content-Type': APPLICATION_JSON_UTF8
    })
};

// should fetch (resource marked dirty or hasn't been seen in 60 seconds)

function isActionOfType(action, typeEnding) {
    return endsWith(action.type, typeEnding);
}

function makeActionOfType(action, typeEnding) {
    return endsWith(action.type, typeEnding);
}

// const fetchMiddleware = fetchImplementation => store => next => action => {
const fetchMiddleware = () => store => next => action => {

    if (action.type === API_FETCH) {

    // if (isActionOfType(action, CANCEL)) {
    //     //cancel the request
    // }
    // else if (isActionOfType(action, BEGIN)) {
    //     const { url, params, payload } = action;
        const { url, params, payload, kind, stage } = action;

        // Add API Host onto Url
        const fullUrl = `${API_HOST}${url}`;

        let fetchOptions = merge({}, defaultFetchOptions, params)

        // FIXME: replace with userservice lookup
        const googleJwtToken = localStorage.getItem(USER_JWT);

        if (!isEmpty(googleJwtToken)) {
            fetchOptions.headers.set(AUTHORIZATION, `Bearer ${googleJwtToken}`);
            // fetchHeaders.append(AUTHORIZATION, `Bearer ${googleJwtToken}`);
        }

        if (payload !== null && !isEmpty(payload)) {
            fetchOptions.body=  JSON.stringify( payload )
        }

        return fetchRetry(fullUrl, fetchOptions)
        // .then(response => {
        //     // this.setToken(res.token) // Setting the token in localStorage
        //     // return Promise.resolve(res);
        //
        //     // console.log("response of", response)
        //
        //     // if (response.status === 200) {
        //     //     store.dispatch({type: SNACK_OPEN, message: "API worked"})
        //     // }
        //
        //     // if (response.status === 401) {
        //     //     store.dispatch({type: API_ERROR, message: `API Access Denied`});
        //     // } else if (response.status === 400) {
        //     //     store.dispatch({type: API_ERROR, message: `API Bad Request`});
        //     // } else if (response.status === 500) {
        //     //     store.dispatch({type: API_ERROR, message: `API Server error`});
        //     // }
        //
        //     return response;
        //
        //     // store.dispatch({type: FETCH_END});
        //     // return response.json()  //FIXME: return json if nothing went wrong
        //     // return response
        // })
        // // .then(response => response.json())
        // .catch(error => {
        //     console.error(`Fetch Error =`, error);
        //     store.dispatch({type: API_ERROR, message: error.message});
        //     store.dispatch({type: SNACK_OPEN, message: error.message})
        //     return error
        // })
    } else {
        return next(action);
    }
};

// const middleware = applyMiddleware(fetchMiddleware(window.fetch));
// const store = createStore(reducers, middleware);
// Example action



// export default fetchMiddleware
export default fetchMiddleware
