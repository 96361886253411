import React, { Component } from 'react';
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {TextField, Button, Grid, MenuItem, Select, FormControl, InputLabel, FormHelperText, Input} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {googleAuthFailureAction, googleAuthSuccessAction, saveResourceAction} from '../redux/actions'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    // container: {
    //     display: 'flex',
    //     flexWrap: 'wrap',
    // },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    menu: {
        width: 200,
    },
    form: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
            display: 'flex',
            flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit
    }
});

function mySubmit(values) {
    window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
}

// new Promise(resolve => setTimeout(resolve, ms));

const asyncSubmit = (values, dispatch) => {
    return new Promise(resolve => {
        // simulate server latency
        window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
        dispatch({type: "FORM_SUBMITTED"})
    })
}

const validate = values => {
    const errors = {}
    if (!values.firstName) {
        errors.firstName = 'Error: Required'
    } else if (values.firstName != 'Brad') {
        errors.firstName = 'Error: You are not brad'
    }
    // if (!values.lastName) {
    //     errors.lastName = 'Required'
    // }
    // if (!values.email) {
    //     errors.email = 'Required'
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    //     errors.email = 'Invalid email address'
    // }
    // if (!values.sex) {
    //     errors.sex = 'Required'
    // }
    // if (!values.favoriteColor) {
    //     errors.favoriteColor = 'Required'
    // }
    return errors
}

const renderTextField = (
    { input, label, help, multiline, required, meta: { touched, error }, classes, ...custom },
) => (
    <div>
    <TextField
        label={label}
        className={classes.textField}
        error={ !!(touched && error) }
        helperText={touched && error}
        margin="normal"
        multiline={multiline}
        {...input}
        {...custom}
    />
    </div>
);

const renderSelectField = ({
                               // input: { onChange, value, onBlur, ...inputProps },
                               input,
                               label,
                               meta: { touched, error },
                               classes,
                               children
                           }) => (

    <Select
        native
        label={label}
        children={children}
        {...input}
    />
);

// const renderSelectField = ({
//                                input: { onChange, value, onBlur, ...inputProps },
//                                label,
//                                meta: { touched, error },
//                                classes,
//                                children,
//                                ...custom
//                            }) => (
//     <FormControl>
//         <InputLabel htmlFor="driver">Driver</InputLabel>
//         <Field
//             name="driver"
//             component={Select}
//             placeholder="Driver"
//             autoWidth={true}
//             // inputProps={}
//             inputProps={inputProps}
//             value={value}
//             //{/*{...input}*/}
//             // validate={required}
//             children={children}
//             {...custom}
//         >
//             {/*<MenuItem value="alice@redux-pizza.com">Alice</MenuItem>*/}
//             {/*<MenuItem value="bob@redux-pizza.com">Bob</MenuItem>*/}
//             {/*<MenuItem value="carl@redux-pizza.com">Carl</MenuItem>*/}
//         </Field>
//     </FormControl>
//
// )

class SecondForm extends Component {

    constructor(props) {
        super(props)

        // this.state = {
        //     age: 12
        // }
    }

    state = {
        age: '',
        name: 'hai',
    };

    // handleReduxFormChange = (event) => {
    //     onChange(event.target.value);
    //     if (onChangeFromField) {
    //         onChangeFromField(event.target.value);
    //     }
    // },

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    render() {
        // const { classes, pristine, reset, submitting } = this.props;
        const { classes, pristine, reset, submitting, handleSubmit } = this.props;
        // const { age } = this.state;



        const AgeSelect = () => (
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age">Age</InputLabel>
                <Select
                    native
                    // displayEmpty
                    name="age"
                    value={this.state.age}
                    // defaultValue={20}
                    onChange={this.handleChange('age')}
                    // onChange={handleChange('age')}
                    // inputProps={{
                    //     id: 'age-native-simple',
                    //     name: 'age'
                    // }}
                    // autoWidth
                    // placeholder="Age"
                    // autoWidth={true}
                    // margin="normal"

                >
                    {/*<option value=""/>*/}
                    <option />
                    <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>
                </Select>
            </FormControl>
        );

        const AgeField = () => (
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age">Age</InputLabel>
                <Field
                    native
                    name="age"
                    value={this.state.age}
                    // defaultValue={20}
                    onChange={this.handleChange('age')}
                    component={renderSelectField}
                    // autoWidth
                    // placeholder="Age"
                    // autoWidth={true}
                    // margin="normal"
                >
                    {/*<option value=""/>*/}
                    <option />
                    <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>
                </Field>
            </FormControl>
        );
        // const AgeSelectStyled = withStyles(styles)(AgeSelect);


        return (



                <form onSubmit={handleSubmit(this.props.saveResource)} className={classes.form}>
                    <h1>SecondForm Here</h1>

                    <Grid container className={classes.root} spacing={16}>
                    <Grid item sm={8}>
                    <div>
                    <Field
                        name="firstName"
                        component={renderTextField}
                        label="First Name"
                        help="What is your name?"
                        required
                        classes={classes}
                        />
                    </div>
                    <div>
                        <Field
                            name="lastName"
                            component={renderTextField}
                            label="Last Name"
                            classes={classes}
                        />
                    </div>
                    </Grid>
                    <Grid item sm={8}>
                    <div>
                        <Field
                            name="notes"
                            component={renderTextField}
                            label="Notes"
                            multiline
                            classes={classes}
                            rows={3}
                        />
                    </div>
                    <div>
                        <AgeField/>
                    </div>
                    </Grid>
                        <Grid item sm={8}>
                    <div id="actions">
                        <Button variant="raised" color="primary" type="submit" className={classes.button} disabled={pristine || submitting}>
                            Submit
                        </Button>
                        <Button variant="raised" type="button" className={classes.button} disabled={pristine || submitting} onClick={reset}>
                            Clear Values
                        </Button>

                    </div>
                    </Grid>
                    </Grid>
                </form>



        )
    }
};

const mapStateToProps = (state) => ({
    // If we have a resource in focus put the properties here
    // ...
});

const mapDispatchToProps = (dispatch)  => {
    return {
        saveResource(values) { dispatch(saveResourceAction(values))}
    }
};

SecondForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(SecondForm);

SecondForm = withStyles(styles)(SecondForm);

export default reduxForm({
    form: 'second', // a unique name for this form
    validate
})(SecondForm);