// import {Map} from 'immutable'
import {
    RESOURCE_CREATED,
    RESOURCES_LOADING,
    RESOURCES_LOADED,
    RESOURCE_LOADED,
    RESOURCE_SELECTED,
    CREATE_RESOURCE_BEGIN,
    CREATE_RESOURCE_SUCCESS,
    CREATE_RESOURCE_FAILURE,
    FETCH_RESOURCE_BEGIN,
    FETCH_RESOURCE_SUCCESS,
    FETCH_RESOURCE_FAILURE
} from '../actions/resourceActions'

import isEmpty from 'lodash/isEmpty'

const initialState = {
    records: [],
    selected: null,
    lists: {
        filtered: []
    },
    loading: false,
    error: null
};

function createError(message, type, code=0, traceId="") {
    if (isEmpty(message) || isEmpty(type)) {
        return new Error("Message and Type must be supplied for errors ")
    }

    return { message, type, code, traceId };
}

const exampleError = {
    message: "(#803) Some of the aliases you requested do not exist: products",
    type: "OAuthException",
    code: 803,
    traceId: "FOXX2AhLh80"
};

export default function resourcesReducer(state = initialState, action) {
    switch(action.type) {
        // NEW
        case FETCH_RESOURCE_BEGIN:
            return { ...state, isLoading: true, error: null };

        // On success add collection if action.resource is null, otherwise individual object
        case FETCH_RESOURCE_SUCCESS: {
            let records = isEmpty(action.resource) ? action.resources : [...state.records, action.resource];
            return { ...state, records: records, isLoading: false, error: null }
        }

        case RESOURCE_SELECTED: {
            return { ...state, selected: [...state.records].filter(r => r.id === action.id)[0] };
        }

        // On failure keep the records you already had, display the error
        case FETCH_RESOURCE_FAILURE:
            return { ...state, records: [...state.records], isLoading: false, error: action.error };

        // OLD
        case RESOURCES_LOADING:
            return { ...state, isLoading: true, error: null };

        case RESOURCES_LOADED:
            return { ...state, records: action.resources, isLoading: false };

        case RESOURCE_LOADED:
            return { ...state, selected: action.resource, records: [...state.records, action.resource], isLoading: false };

        case RESOURCE_CREATED:
            console.log('resourcesReducer, action is', action)
            return { ...state, result: action.result, isLoading: false };


        default:
            return state
    }
}