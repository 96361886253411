import React, { Component } from 'react';
import {Paper, Typography, withStyles} from '@material-ui/core';
import {connect} from "react-redux";
import {getEventsAction} from "../redux/actions";
import MockEventsTable from '../mocks/eventsTable'
import {StyledCircularProgress} from "../components/Progress";



export const EventsPage = () => (
    <div>
        <Paper>
            <Typography>Events</Typography>
            <EventsContainer/>
            <MockEventsTable/>
        </Paper>

    </div>
);




class EventsList extends Component {

    componentDidMount() {
        this.props.loadEvents()
    }

    render() {
        const events = this.props.events.records;

        return this.props.isLoading
            ? <StyledCircularProgress /> :
            <div>{events.map((e, i) => (
                <div key={i}>
                    <span>Message: {e.message}</span>
                </div>))}
            </div>
    }
}


const mapStateToProps = (state) => ({
    isLoading: state.events.isLoading,
    events: state.events,
})

const mapDispatchToProps = (dispatch) => {
    return {
        loadEvents() {
            dispatch(getEventsAction());
        },
    }
};

const EventsContainer = connect(mapStateToProps, mapDispatchToProps)(EventsList);