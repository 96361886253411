import { SNACK_OPEN, SNACK_DISMISS, SNACK_UNDO } from '../actions'

export default function snacksReducer(state={message: null, open: false, undoAction: null}, action) {
    switch(action.type) {

        case SNACK_OPEN:
            return { ...state, open: true, message: action.message, undoAction: action.undoAction };

        case SNACK_DISMISS:
            return { ...state, open: false, message: null, undoAction: null };


        default:
            return state
    }
}