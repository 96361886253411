import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import {connect} from "react-redux";
import {getSolutionsAction} from "../../redux/actions/index";
import {StyledCircularProgress} from "../../components/Progress";
import { Link }  from "react-router-dom";
import {Paper, Toolbar, Typography, Table, TableBody, TableCell, TableHead, TableRow, List, ListItem, ListItemText } from "@material-ui/core";
import {FormattedDate, FormattedTime} from "react-intl";
import {FormattedDateTime} from "../../components/FormattedDateTime";
import {shortId} from "../../util/functions";

class SolutionsPage extends Component {

    componentWillMount() {
        this.props.getSolutionsAction()
    }

    render() {

        const {isLoading, solutions} = this.props

        return isLoading? <StyledCircularProgress /> : <SolutionList solutions={solutions}/>

    }
}

const mapStateToProps = (state) => ({
    isLoading: state.solutions.isLoading,
    solutions: state.solutions.records,
})

export default connect(mapStateToProps, {getSolutionsAction})(SolutionsPage);


export const SolutionList = ({solutions}) => (
    <Paper>
        <Toolbar>
            <Typography variant="title">Solutions</Typography>
        </Toolbar>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Event</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {solutions.map(solution => {
                return (
                  <TableRow key={solution.id}>
                    <TableCell><Link to={`/solutions/${solution.id}`}>{shortId(solution.id)}</Link></TableCell>
                    <TableCell><Link to={`/plans/${solution.plan.id}`}>{solution.plan.name}</Link></TableCell>
                    <TableCell>{ `${solution.event.eventType} at ${solution.event.startDateTime}`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
    </Paper>
);


