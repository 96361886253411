import React from 'react';

import { HomePage } from './HomePage'
import { EventsPage } from './EventsPage'
import { EventPage } from './EventPage'
import GridEventsPage from './GridEventsPage'
import GridEventPage from './GridEventPage'
import  HarnessPage  from './HarnessPage'
import PlansPage from './plans/PlansPage'
import PlanPage from './plans/PlanPage'
import  SolutionsPage from './solutions/SolutionsPage'
import SolutionPage  from './solutions/SolutionPage'
import ResourcesPage  from './resources/ResourcesPage'
import ResourcePage  from './resources/ResourcePage'
import ResourceSchedulePage  from './resources/ResourceSchedulePage'
import ResourceReadingsPage  from './resources/ResourceReadingsPage'
import { ReadingsPage } from './ReadingsPage'
import { SolverPage } from './SolverPage'
import { NotFoundPage } from './NotFoundPage'
import { LoginHarnessPage } from './LoginHarnessPage'
import { LoginPage } from './LoginPage'
import DevelopersPage from './DevelopersPage'
import MarketsPage from './markets/MarketsPage'
import MarketPage from './markets/MarketShowPage'
import MarketSchedulesPage from './markets/MarketSchedulesPage'
import ChartsPage from './ChartPage'


// Cluster everything under Page element
export default { HomePage,
    EventsPage,
    EventPage,
    GridEventsPage,
    GridEventPage,
    HarnessPage,
    PlansPage,
    PlanPage,
    SolutionsPage,
    SolutionPage,
    ResourcesPage,
    ResourcePage,
    ResourceSchedulePage,
    ResourceReadingsPage,
    ReadingsPage,
    SolverPage,
    NotFoundPage,
    LoginHarnessPage,
    LoginPage,
    MarketsPage,
    MarketPage,
    MarketSchedulesPage,
    DevelopersPage,
    ChartsPage,
}
