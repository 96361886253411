import React from 'react';
import classNames from 'classnames';
import withStyles  from '@material-ui/core/styles/withStyles';
import {
    Paper,
    Typography,
    Table,
    TableHead,
    TableRow, TableCell, TableBody, Button
} from '@material-ui/core';

import { getResourceAction } from "../../redux/actions/index";
import {StyledCircularProgress} from "../../components/Progress";
import {RowData} from "../../components/RowData";
import {connect} from "react-redux";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import { FormattedDateTime}  from "../../components/FormattedDateTime"
import * as PropTypes from "prop-types";
import ResourcesTable from "../../components/ResourcesTable";
import {Link} from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 500,
    },
    resourceView: {
        padding: '24px', // fixme get numbers from somewhere else
        maxWidth: 500,
    },
    stateView: {
        padding: '24px',
        maxWidth: 500
    },
    devicesView: {
        padding: '24px'
    },
    traitView: {
        padding: '24px',
        maxWidth: 500
    },
    rowData: {
        color: theme.primary,
    },
    button: {
        margin: theme.spacing.unit,
    },
    pageTitle: {
        flexGrow: 1
    },
    paper: {
        marginBottom: '10px'
    }
});

class ResourcePage extends React.Component {

    componentWillMount() {
        const id = this.props.match.params.id;
        if (id) {
            this.props.getResourceAction(id)
        }
    }

    render() {
        const {resource, classes, isLoading} = this.props;
        const resourceIsLoading = isLoading || !resource;

        return (

            <React.Fragment>
                <Paper className={classes.paper}>
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="title" className={classes.pageTitle}>Resource</Typography>
                        { resourceIsLoading ||  <div className={classes.buttons}>
                            <Button component={Link} to={`/resources/${resource.id}/schedule`} className={classes.button}>
                                <EventBusyIcon />Schedule
                            </Button>
                            <Button component={Link} to={`/resources/${resource.id}/readings`} className={classes.button}>
                                <NetworkCheckIcon />Readings
                            </Button>
                            <Button component={Link} to={`/resources/${resource.id}/edit`} className={classes.button}>
                                <EditIcon />Edit
                            </Button>
                        </div>}
                    </Toolbar>
                </Paper>

                <Paper className={classes.paper}>

                    { resourceIsLoading ? (
                        <StyledCircularProgress />
                    ) : (
                        <React.Fragment>
                            <ResourceView resource={resource} classes={classes}/>
                            <ResourceTraitView traits={resource.traits} classes={classes}/>
                            <ResourceStateView state={resource.state} classes={classes}/>
                            <ResourceDevicesView devices={resource.devices} classes={classes}/>
                        </React.Fragment>
                    )}
                </Paper>
            </React.Fragment>
        );
    }
}


const ResourceView = ({resource, classes}) => (
    <div className={classes.resourceView}>
        <Typography variant="subheading" color="textSecondary">Properties</Typography>
        <Table><TableBody>
                <RowData name="ID" value={resource.id}/>
                <RowData name="Name" value={resource.name}/>
                <RowData name="Type" value={resource.type}/>
                <RowData name="Updated" date value={resource.updatedOn}/>
                <RowData name="Labels" value={resource.labels.map(l => { return `${l.key}=${l.value}`})}/>
        </TableBody></Table>
    </div>
);

const ResourceStateView = ({state, classes}) => (
    <div className={classes.stateView} >
        <Typography variant="subheading" color="textSecondary" >State</Typography>
        <Table><TableBody>
            <RowData name="Last Command At" date value={state.lastCommandAt}/>
            <RowData name="Last Read" date value={state.lastRead}/>
            <RowData name="Comms Failure Count" value={state.commsFailureCount}/>
            <RowData name="Comms Failure At" date value={state.commsFailureAt}/>

            <RowData name="Predictor Failure Count" value={state.predictorFailureCount}/>
            <RowData name="Predictor Failure At" date value={state.predictorFailureAt}/>
        </TableBody></Table>
    </div>
);

const ResourceTraitView = ({traits, classes}) => (
    <div className={classes.traitView} >
        <Typography variant="subheading" color="textSecondary" >Traits</Typography>
        <Table>
            <TableBody>
                <RowData name="PowerCapacityKW" value={traits.powerCapacityKw}/>
                <RowData name="EnergyCapacityKW" value={traits.energyCapacityKwh}/>
                <RowData name="CostKwh" value={traits.costKwh}/>
                <RowData name="Availability" value={traits.availability}/>
                <RowData name="Max Event Mins" value={traits.maxEventMins}/>
                <RowData name="Min Break Mins" value={traits.minBreakMins}/>
            </TableBody>
        </Table>
    </div>
);

const ResourceDevicesView = ({devices, classes}) => (
    <div className={classes.devicesView} >
        <Typography variant="subheading" color="textSecondary" >Devices</Typography>
        <Typography variant="caption">TODO: mark the Control as special</Typography>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Xid</TableCell>
                    <TableCell>Serial</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {devices.map(d => {
                    return (
                        <TableRow key={d.xid}>
                            <TableCell>{d.name}</TableCell>
                            <TableCell>{d.type}</TableCell>
                            <TableCell>{d.xid}</TableCell>
                            <TableCell>{d.serial}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </div>
);

// val name: String = "",
//         var type: String = "",
//         val xid:String = "",
//         val serial:String = "",
//         val password:String = ""
// {"name":"Cold Store","type":"coldstore","state":{"stateOfCharge":0,"stateOfFuel":30,"currentTemp":0,"totalContractedHours":0,"totalUsedHours":0,"hoursTillAvailable":0,"id":"a44160cf-23d2-4f1e-b241-4ab157f2e12c","updatedOn":"2018-07-26T10:04:13.792","createdOn":"2018-07-26T10:04:13.792"},"control":null,"devices":[],"labels":[{"key":"gxp","value":"kwa0111","type":"user","id":"4dddf333-8cf8-4425-9d88-b35866b8681f","updatedOn":"2018-07-26T10:04:13.791","createdOn":"2018-07-26T10:04:13.791"}],"powerCapacityKw":0,"energyCapacityKwh":0,"costKwh":0,"id":"33e83f3e-e397-48fb-8a11-16a996077395","updatedOn":"2018-07-26T10:04:13.792","createdOn":"2018-07-26T10:04:13.792"}



const mapStateToProps = (state) => ({
    isLoading: state.resources.isLoading,
    resource: state.resources.selected,
});

ResourcePage = connect(mapStateToProps, {getResourceAction})(ResourcePage);

export default withStyles(styles)(ResourcePage);
