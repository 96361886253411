import React from 'react';
import {Paper, Typography} from '@material-ui/core';

export const LoginHarnessPage = () => (
    <div>
        <Paper>
            <Typography>LoginHarnessPage</Typography>

            {/*{googleLoginNV}*/}

        </Paper>



    </div>
);