import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Table, { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core/Table';
import {Toolbar, Typography, Paper, Table, TableBody, TableCell, TableHead, TableRow  } from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

let id = 0;
function createData(name, eventType, demand, effectiveFrom, selectors, auto, priority) {
    id += 1;
    return { id, name, eventType, demand, effectiveFrom, selectors, auto, priority };
}




const data = [
    createData('Orion Morning', 'ORON-SCHED', 20, 'April-1', 'network=oron,devicetype=paladin', true, 100),
    createData('WLG Freq Response', 'WLG-FREQ', -1, 'April-1', 'network=ckhk', true, 100),
    createData('Tes Plan', 'TEST-1', 2, 'April-1', 'network=contact-internal,devicetype=redback1', true, 10),
];

function SimpleTable(props) {
    const { classes } = props;

    return (
        <Paper className={classes.root}>
            <Toolbar>
                <Typography variant="title">Plans</Typography>
            </Toolbar>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Event Type</TableCell>
                        <TableCell numeric>Demand (MW)</TableCell>

                        <TableCell>Effective From</TableCell>
                        <TableCell>Selectors</TableCell>
                        <TableCell>Auto</TableCell>
                        <TableCell numeric>Priority</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(n => {
                        return (
                            <TableRow key={n.id}>
                                <TableCell>{n.name}</TableCell>
                                <TableCell>{n.eventType}</TableCell>
                                <TableCell numeric>{n.demand}</TableCell>
                                <TableCell>{n.effectiveFrom}</TableCell>
                                <TableCell>{n.selectors}</TableCell>
                                <TableCell>{n.auto}</TableCell>
                                <TableCell numeric>{n.priority}</TableCell>

                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Paper>
    );
}

SimpleTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);