import { EVENT_CREATED, EVENTS_LOADING, EVENTS_LOADED, EVENT_LOADED, EVENT_PUBLISHED } from '../actions/eventActions'

export default function eventsReducer(state={records: [], isLoading: false}, action) {
    switch(action.type) {

        case EVENTS_LOADING:
            return { ...state, isLoading: true };

        case EVENTS_LOADED:
            return { ...state, records: action.events, isLoading: false };

        case EVENT_LOADED:
            return { ...state, records: [...state.records, action.event], isLoading: false };

        case EVENT_CREATED:
            return { ...state, result: action.result, isLoading: false };

        case EVENT_PUBLISHED:
            return { ...state, records: [...state.records, action.event], isLoading: false };

        default:
            return state
    }
}
