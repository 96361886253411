import React, { Component } from 'react';
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {TextField, Button, Grid, MenuItem, Select, FormControl, InputLabel, FormHelperText, Input, Radio} from "@material-ui/core";
import withStyles  from '@material-ui/core/styles/withStyles';
import { saveEventAction } from '../redux/actions/index'
import { renderTextField, renderSelectField, renderDatePicker, renderRadioGroup } from './FormHelpers'
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import DateFnsUtils from "material-ui-pickers/utils/date-fns-utils";
import enLocale from "date-fns/locale/en-US";



const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    dateTimeField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    menu: {
        width: 200,
    },
    form: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit
    }
});

const validate = values => {
    const errors = {}
    if (!values.eventType) {
        errors.eventType = 'Error: Required'
    } else if (values.eventType != 'Brad') {
        errors.eventType = 'Error: You are not brad'
    }
    return errors
};



class EventForm extends Component {

    constructor(props) {
        super(props)

    }

    handleChange = name => event => {
        console.log('handleChange')
        this.setState({ [name]: event.target.value });
    };

    handleDateChange = (props) => {
        console.log('handleDateChange', props)
        // this.setState({ selectedDate: props });
    }

    handlePickerChange = (props) => {
        // let {name, value} = props
        console.log('handlePickerChange', props)
        let {name, value} = props
        this.setState(value);
    }

    formatDateForPicker = (value, name) => {
        console.log('formatDateForPicker in: ', value, name)
        let date = new Date(value)
        console.log('formatDateForPicker out: ', date)
        return date
    };

    // normalizeDateFromPicker = (date) => {
    //     console.log('normalizeDateFromPicker in', name, value,)
    //     let isoString = value.toISOString()
    //     return {name, isoString}
    // };

    parseDateFromPicker = (value, name) => {
        console.log('parseDateFromPicker in', name, value)

        return value.toISOString()
        // return {name: value.toISOString()}
    }

    render() {
        const { classes, pristine, reset, submitting, handleSubmit } = this.props;

        const EventTypeField = () => (
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="eventType">EventType</InputLabel>
                <Field
                    native
                    name="type"
                    onChange={this.handleChange('eventType')}
                    component={renderSelectField}>
                    <option />
                    {/*<option value="frequency">Frequency</option>*/}
                    <option value="dr">Dispatch</option>
                </Field>
            </FormControl>
        );

        return (

            <form onSubmit={handleSubmit(this.props.saveEvent)} className={classes.form}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>

                <Grid container className={classes.root} spacing={16}>
                    <Grid item>
                        <Field name="name" component={renderTextField} label="Name" help="Name of the event?"
                               required classes={classes} />
                        <EventTypeField/>

                        <Field name="selectorExpressionName" component={renderTextField} label="Selector Expression" help="Selector"
                               required classes={classes} />
                        <Field name="kwRequested" component={renderTextField} label="Capacity Required (KW)"
                               classes={classes} />
                    </Grid>
                    <Grid item>

                        <Field name="periods" component={renderTextField} label="Periods" help="How many?" classes={classes} />
                        <Field name="periodLengthMinutes" component={renderTextField} label="Period Lengths" help="5/30" classes={classes} />

                        {/*<Field name="periodLengthMinutes"*/}
                               {/*component={renderRadioGroup}*/}
                               {/*label="Period Length (mins)"*/}
                               {/*help="How long 5/30?"*/}
                               {/*classes={classes} >*/}
                            {/*<Radio value="5" label="5" />*/}
                            {/*<Radio value="30" label="30" />*/}
                        {/*</Field>*/}


                        {/*<FormPickers/>*/}

                        <Field name="startDate"
                               component={renderDatePicker}
                               label="Start Date"
                               format = {this.formatDateForPicker}
                               parse={this.parseDateFromPicker}
                               onChange={this.handlePickerChange}
                               classes={classes} />

                        <Field name="endDate"
                               component={renderDatePicker}
                               label="End Date"
                               format = {this.formatDateForPicker}
                               parse={this.parseDateFromPicker}
                               onChange={this.handlePickerChange}
                               classes={classes} />






                    </Grid>
                    <Grid item>
                        <div id="actions">
                            <Button variant="raised" color="primary" type="submit" className={classes.button} disabled={pristine || submitting}>
                                Create Event
                            </Button>
                            <Button variant="raised" type="button" className={classes.button} disabled={pristine || submitting} onClick={reset}>
                                Reset
                            </Button>

                        </div>
                    </Grid>
                </Grid>
                </MuiPickersUtilsProvider>
            </form>



        )
    }
};

const mapStateToProps = (state) => ({
    // If we have a resource in focus put the properties here
    // ...
});

const mapDispatchToProps = (dispatch)  => {
    return {
        saveEvent(values) { dispatch(saveEventAction(values))}
    }
};

EventForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(EventForm);

EventForm = withStyles(styles)(EventForm);

const initial = {
    name: 'Test-1',
    startDate: '2018-05-05T02:18:00.000Z',
    endDate: '2018-05-10T04:18:00.000Z',
    type: 'dr',
    kwRequested: [20,20,20,20],
    selectorExpressionName: 'all',
    periods: 4,
    periodLengthMinutes: 30,
}

export default reduxForm({
    form: 'event', // a unique name for this form
    initialValues: initial
    // validate
})(EventForm);