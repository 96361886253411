import React from 'react';
import PropTypes from 'prop-types';
import withStyles  from '@material-ui/core/styles/withStyles';
//import Table, { TableBody, TableCell, TableHead, TableRow Toolbar, Typography, Paper, } from '@material-ui/core/Table';
import {TableBody, TableCell, TableHead, TableRow, Table } from "@material-ui/core";
import {Link} from "react-router-dom";
import split from 'lodash/split';


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

function ResourcesTable(props) {
    const { classes, resources } = props;

    return (
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Traits</TableCell>
                        <TableCell>Labels</TableCell>
                        <TableCell numeric>Heartbeat (secs ago)</TableCell>
                        <TableCell>Heath</TableCell>
                        <TableCell>Notes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resources.map(n => {
                        let shortId = split(n.id,'-')[0];
                        return (
                            <TableRow key={n.id}>
                                <TableCell><Link to={`/resources/${n.id}`}>{shortId}</Link></TableCell>
                                <TableCell>{n.name}</TableCell>
                                <TableCell>{n.type}</TableCell>
                                <TableCell>{JSON.stringify(n.traits)}</TableCell>
                                <TableCell>
                                    {n.labels.map(l => {
                                        return `${l.key}=${l.value}`
                                    })}
                                </TableCell>
                                <TableCell numeric>{n.heartbeat}</TableCell>
                                {/*<TableCell>{n.state.ok?<font color="#006400">OK</font>:<font color="red">FAILED</font>}</TableCell>*/}
                                <TableCell>{n.notes}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
    );
}

ResourcesTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResourcesTable);
