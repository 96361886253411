import React, {Component} from 'react';
import {Paper, TableCell, TableRow, Toolbar, Typography} from '@material-ui/core';
import {connect} from "react-redux";
import {
    snackShowAction,
    getResourceAction,
    getResourceScheduleAction} from "../../redux/actions";
import {StyledCircularProgress} from "../../components/Progress";
import ResourceScheduleTable from "../../components/ResourceScheduleTable";
import {Link, withRouter, Navlink} from 'react-router-dom'
import withStyles from "@material-ui/core/styles/withStyles";
import {shortId} from "../../util/functions";

const styles = theme => ({
    root: {
    },
    button: {
        margin: theme.spacing.unit,
    },
    pageTitle: {
        flexGrow: 1
    },
    paper: {
        marginBottom: '10px'
    }
});

class ResourceSchedulePage extends Component {

    componentWillMount() {
        const id = this.props.match.params.id;
        if (id) {
            this.props.getResourceAction(id);
            this.props.getResourceScheduleAction(id);
        }
    }

    render() {
        const {scheduleLoading, resourceLoading, resource, resourceSchedules, classes} = this.props;
        let loaded = !scheduleLoading && !resourceLoading && resource;

        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Toolbar className={classes.toolbar}>
                        { loaded &&
                        <Typography variant="title" className={classes.pageTitle}>Resource Schedule
                            for <Link to={`/resources/${resource.id}`}>{resource.name} {shortId(resource.id)}</Link>
                        </Typography>
                        }
                    </Toolbar>
                </Paper>

                <Paper className={classes.paper}>
                    { !loaded ? (
                        <StyledCircularProgress />
                    ) : (
                        <ResourceScheduleTable resource={resource} resourceSchedules={resourceSchedules} />
                    )}
                </Paper>


            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    scheduleLoading: state.resourceSchedules.isLoading,
    resourceLoading: state.resources.isLoading,
    resourceSchedules: state.resourceSchedules.records,
    resource: state.resources.selected,
});

// Enable ability to view where we at
ResourceSchedulePage = withRouter(ResourceSchedulePage);
ResourceSchedulePage = connect(mapStateToProps, {
    getResourceScheduleAction,
    getResourceAction,
    snackShowAction
})(ResourceSchedulePage);

export default withStyles(styles)(ResourceSchedulePage);