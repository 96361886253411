import {
    FETCH_RESOURCE_SCHEDULE_BEGIN,
    FETCH_RESOURCE_SCHEDULE_SUCCESS,
    FETCH_RESOURCE_SCHEDULE_FAILURE
} from '../actions/resourceActions'

const initialState = {
    records: [],
    selected: null,
    lists: {
        filtered: []
    },
    loading: false,
    error: null
};


export default function resourceSchedulesReducer(state = initialState, action) {
    switch(action.type) {

        case FETCH_RESOURCE_SCHEDULE_BEGIN:
            return { ...state, isLoading: true, error: null };

        // On success add collection if action.resource is null, otherwise individual object
        case FETCH_RESOURCE_SCHEDULE_SUCCESS:
            return { ...state, records: action.resourceSchedules, isLoading: false, error: null };

        // On failure keep the records you already had, display the error
        case FETCH_RESOURCE_SCHEDULE_FAILURE:
            return { ...state, records: [...state.records], isLoading: false, error: action.error };

        default:
            return state
    }
}