import React, { Component } from 'react';
import {Button, withStyles} from '@material-ui/core';
import {connect} from "react-redux";
import {getMarketSchedulesAction, getMarketSummariesAction} from "../../redux/actions";
import {StyledCircularProgress} from "../../components/Progress";
import {Paper, Toolbar, Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {Link} from "react-router-dom";
import uniqueId from 'lodash/uniqueId'
import {shortId} from "../../util/functions";
import {FormattedDateTime} from "../../components/FormattedDateTime";
import AddIcon from "../../../node_modules/@material-ui/icons/Add";
import DeleteIcon from "../../../node_modules/@material-ui/icons/Delete";

class MarketSchedulesPage extends Component {

    componentWillMount() {
        this.props.getMarketSchedulesAction()
        this.props.getMarketSummariesAction()
    }

    render() {
        const {isMarketsLoading,
            marketSchedules,
            isSummariesLoading,
            marketSummaries,
        classes} = this.props;
        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="title" className={classes.pageTitle}>Markets Summaries</Typography>
                    </Toolbar>
                </Paper>
                {isSummariesLoading? <StyledCircularProgress /> : <MarketSummariesTable {...this.props}/>}
                {isMarketsLoading? <StyledCircularProgress /> : <MarketSchedulesList {...this.props}/>}
            </React.Fragment>
        )
    }
}


const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    pageTitle: {
        flexGrow: 1
    },
    paper: {
        marginBottom: '10px'
    }
});

export const MarketSummariesTable = ({classes, marketSummaries}) => (
    <Paper className={classes.paper}>
        <Toolbar>
            <Typography variant="subheading">Summary</Typography>
        </Toolbar>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell>Market</TableCell>
                    <TableCell numeric>Dispatched</TableCell>
                    <TableCell numeric>Bidded</TableCell>
                    <TableCell numeric>Demanded</TableCell>
                    <TableCell numeric>Accepted</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {marketSummaries.map(ms =>
                    <TableRow key={uniqueId()}>
                        <TableCell><FormattedDateTime value={ms.periodStart}/></TableCell>
                        <TableCell><FormattedDateTime value={ms.periodEnd}/></TableCell>
                        <TableCell>{ms.market}</TableCell>
                        <TableCell numeric>${ms.dispatchedPrice} / {ms.dispatchedKw}Kw</TableCell>
                        <TableCell numeric>${ms.biddedPrice} / {ms.biddedKw}Kw</TableCell>
                        <TableCell numeric>${ms.demandedPrice} / {ms.demandedKw}Kw</TableCell>
                        <TableCell numeric>${ms.acceptedPrice} / {ms.acceptedKw}Kw</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </Paper>
)


export const MarketSchedulesList = ({classes, marketSchedules}) => (
    <Paper className={classes.paper}>
        <Toolbar>
            <Typography variant="subheading">Schedule</Typography>
        </Toolbar>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell>Market</TableCell>
                    <TableCell>ScheduleType</TableCell>
                    <TableCell numeric>Kw</TableCell>
                    <TableCell numeric>Price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {marketSchedules.map(ms => ms.items.map(
                    item =>
                        <TableRow key={uniqueId()}>
                            <TableCell><FormattedDateTime value={item.startAt}/></TableCell>
                            <TableCell><FormattedDateTime value={item.endAt}/></TableCell>
                            <TableCell>{item.market.name}</TableCell>
                            <TableCell>{item.scheduleType}</TableCell>
                            <TableCell numeric>{item.kw}</TableCell>
                            <TableCell numeric>{item.price}</TableCell>
                        </TableRow>
                ))}
            </TableBody>
        </Table>
    </Paper>
);

const mapStateToProps = (state) => ({
    isMarketsLoading: state.marketSchedules.isLoading,
    marketSchedules: state.marketSchedules.records,
    isSummariesLoading: state.marketSummaries.isLoading,
    marketSummaries: state.marketSummaries.records,
});

MarketSchedulesPage = connect(mapStateToProps, {getMarketSchedulesAction, getMarketSummariesAction})(MarketSchedulesPage);

export default withStyles(styles)(MarketSchedulesPage);