import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import {connect} from "react-redux";
import {getGridEventsAction} from "../redux/actions";
import {StyledCircularProgress} from "../components/Progress";
import { Link }  from "react-router-dom";
import {Paper, Toolbar, Typography, Table, TableBody, TableCell, TableHead, TableRow, List, ListItem, ListItemText } from "@material-ui/core";
import {shortId} from "../util/functions";

const styles = theme => ({

});

class GridEventsPage extends Component {

    componentWillMount() {
        this.props.getGridEventsAction()
    }

    render() {

        const {isLoading, events} = this.props;


        return isLoading? <StyledCircularProgress /> : <GridEventsList events={events}/>



    }
}


export const GridEventsList = ({events}) => (
    <Paper>
        <Toolbar>
            <Typography variant="title">Grid Events</Typography>
        </Toolbar>

        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>First Detected</TableCell>
                    <TableCell>Last Detected</TableCell>
                    <TableCell>GridPoints</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {events.map(e => {
                    return (
                        <TableRow key={e.id}>
                            <TableCell><Link to={`/grid-events/${e.id}`}>{shortId(e.id)}</Link></TableCell>
                            <TableCell>{e.firstDetectedAt}</TableCell>
                            <TableCell>{e.lastDetectedAt}</TableCell>
                            <TableCell>
                            {e.gridPoints.map(gp => {
                            return `${gp}`
                            })}
                            </TableCell>
                            <TableCell>
                            {e.actions.map(a => {
                            return `${a.actionType} at ${a.actionAt}`
                            })}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </Paper>
);

const mapStateToProps = (state) => ({
    isLoading: state.gridEvents.isLoading,
    events: state.gridEvents.records,
});

export default connect(mapStateToProps, {getGridEventsAction})(GridEventsPage);

// export default withStyles(styles)(MarketsPage);