import React from 'react'
import {connect} from "react-redux";
import {publishEventAction} from "../redux/actions";
// import EventSource from 'event-source'
require('eventsource');



class EventSourceHandler extends React.Component {

    componentWillMount() {
        console.log("EventSourceHandler will mount, props:", this.props);
        let auth = this.props.auth
        console.log("EventSourceHandler token:", auth.accessToken.access_token);

        // let headers = { Authorization: auth.accessToken.access_token }

        // const evtSource = new EventSource(this.props.sourceUrl);
        const evtSource = new EventSource(
            this.props.sourceUrl,
            { headers: { Authorization: auth.accessToken.id_token } }
            );

        this.setState({"eventSource": evtSource})
        this.setState({"connected": true})


        evtSource.onmessage = (e) => {
            console.log("Message arrived:", e.data)
            this.props.publishEventAction(e.data)
        }
    }

    // componentWillUnmount() {
    //     var connected = this.state.get("connected")
    //     console.log("EventSourceHandler is unmounting, props:", this.props)
    //     console.log("connected state on unmounting, props:", this.props)
    // }


    render() {
        // This doesn't render directly
        return null

    }
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//         publish(message) { dispatch(publishEventAction(message))}
//     }
// };

export default connect(null, {publishEventAction})(EventSourceHandler)
