import React, {Component} from 'react';
import {Paper, Typography} from '@material-ui/core';
// import MyForm from '../mocks/myForm'
// import ResourceForm from '../mocks/resourceForm'
import EventForm from '../components/EventForm'
import MuiForm from '../mocks/muiForm'
import SecondForm from '../mocks/secondForm'
import showResults from '../mocks/showResults'
import Snackbar from '../mocks/simpleSnackbar'
import EventList from "../components/EventList";
import {SolutionList} from "./solutions/SolutionsPage";
import {getEventsAction, triggerEventAction, getSolutionsAction} from "../redux/actions"
import {connect} from "react-redux";
import {Toolbar} from "@material-ui/core/es/index";
import {StyledCircularProgress} from "../components/Progress";
// import '../mocks/myForm.css'

class HarnessPage extends Component {

    componentDidMount() {
        this.props.getEventsAction()
        this.props.getSolutionsAction()
    }

    render() {
        const { events, solutions, eventsLoading, solutionsLoading, triggerEventAction } = this.props;

        return (
            <div>
                <Paper>
                    <Toolbar>
                        <Typography variant="title">Test Harness Page</Typography>
                    </Toolbar>

                    <EventForm/>

                    { eventsLoading ? <StyledCircularProgress /> : <EventList events={events} onClickHandler={triggerEventAction}/> }
                    { solutionsLoading ? <StyledCircularProgress /> : <SolutionList solutions={solutions}/> }
                    {/*<MuiForm onSubmit={showResults}/>*/}
                    {/*<Snackbar/>*/}

                </Paper>


            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    eventsLoading: state.events.isLoading,
    events: state.events.records,
    solutionsLoading: state.solutions.isLoading,
    solutions: state.solutions.records,
})

HarnessPage.defaultProps = {
    eventsLoading: false,
    events: [],
    solutionsLoading: false,
    solutions: [],
};

export default connect(mapStateToProps, {
    getEventsAction,
    getSolutionsAction,
    triggerEventAction
})(HarnessPage)