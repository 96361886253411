import React, {Component} from 'react';
import { Link }  from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import AlarmIcon from '@material-ui/icons/Alarm';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import AssignmentIcon from '@material-ui/icons/Assignment';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import BuildIcon from '@material-ui/icons/Build';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import PieChartIcon from '@material-ui/icons/PieChart';
import SecurityIcon from '@material-ui/icons/Security';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import withStyles  from '@material-ui/core/styles/withStyles';

const styles = {};

const pages = [
    { title: "Home", icon: <HomeIcon />, link: '/'},
    { title: "Events", icon: <AlarmIcon />, link: '/grid-events'},
    { title: "Plans", icon: <AssignmentIcon />, link: '/plans'},
    { title: "Solutions", icon: <BuildIcon />, link: '/solutions'},
    { title: "Resources", icon: <SettingsRemoteIcon />, link: '/resources'},
    { title: "Markets", icon: <MoneyIcon />, link: '/markets'},
    { title: "Market Schedules", icon: <EventBusyIcon />, link: '/market-schedules'},

    { title: "Test Harness", icon: <QueryBuilderIcon />, link: '/harness'},
    { title: "Solver", icon: <PieChartIcon />, link: '/solver'},
    { title: "Developers", icon: <SecurityIcon />, link: '/developers'},
    { title: "Charts", icon: <NetworkCheckIcon />, link: '/charts'},
    // { title: "Login Harness Page", icon: <LockOpenIcon />, link: '/loginharness'},
    // { title: "Login Page", icon: <LockOpenIcon />, link: '/login'},
];

class AdminNavMenuList extends Component {

    render() {

        return (
        <List>{pages.map((page, i) => (
            <ListItem key={i} button={true} component={Link} to={page.link}>
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText primary={page.title}/>
            </ListItem>
            ))}
        </List>
        )
    }
}

export default withStyles(styles)(AdminNavMenuList);