import React from 'react';
import {Paper, Typography} from '@material-ui/core';

export const NotFoundPage = () => (
    <div>
        <Paper>
            <Typography>Page not Found</Typography>
        </Paper>



    </div>
);