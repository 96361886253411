import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export default function (ComposedComponent) {
    class WithAuthentication extends Component {

        // componentWillMount() {
        //     if (!this.props.authenticated) {
        //         this.props.history.push('/login');
        //     }
        // }

        static getDerivedStateFromProps(props, state) {
            if (!props.authenticated) {
                props.history.push('/login');
            }
        }
        //
        // componentWillUpdate(nextProps) {
        //     if (!nextProps.authenticated) {
        //         this.props.history.push('/login');
        //     }
        // }

        PropTypes = {
            router: PropTypes.object,
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return { authenticated: state.auth.authenticated };
    }

    return connect(mapStateToProps)(WithAuthentication);
}