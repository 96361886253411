import {
    GRID_EVENT_SELECTED,
    FETCH_GRID_EVENT_BEGIN,
    FETCH_GRID_EVENT_SUCCESS,
    FETCH_GRID_EVENT_FAILURE,
    FETCH_GRID_EVENTS_BEGIN,
    FETCH_GRID_EVENTS_SUCCESS,
    FETCH_GRID_EVENTS_FAILURE
} from '../actions/gridEventsActions'

const initialState = {
    records: [],
    selected: null,
    lists: {
        filtered: []
    },
    loading: false,
    error: null
};


export default function gridEventsReducer(state = initialState, action) {
    switch(action.type) {

        case GRID_EVENT_SELECTED:
            return { ...state, selected: [...state.records].filter(r => r.id === action.id)[0] };

        case FETCH_GRID_EVENTS_BEGIN:
            return { ...state, isLoading: true, error: null };

        // On success add collection if action.resource is null, otherwise individual object
        case FETCH_GRID_EVENTS_SUCCESS:
            return { ...state, records: action.gridEvents, isLoading: false, error: null };

        // On failure keep the records you already had, display the error
        case FETCH_GRID_EVENTS_FAILURE:
            return { ...state, records: [...state.records], isLoading: false, error: action.error };

        case FETCH_GRID_EVENT_BEGIN:
            return { ...state, isLoading: true, error: null };

        case FETCH_GRID_EVENT_SUCCESS:
            return { ...state, records: [...state.records, action.gridEvent], isLoading: false, error: null };

        case FETCH_GRID_EVENT_FAILURE:
            return { ...state, records: [...state.records], isLoading: false, error: action.error};

        default:
            return state
    }
}
