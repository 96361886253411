export const SNACK_OPEN = 'SNACK_OPEN';
export const SNACK_DISMISS = 'SNACK_DISMISS';
export const SNACK_UNDO = 'SNACK_UNDO';

export const snackShowAction = (message, undoAction=null) => {
    return async (dispatch) => {
        dispatch({type: SNACK_OPEN,
            message: message,
            undoAction: undoAction});
    }
};

export const snackDismissAction = () => ({
    type: SNACK_DISMISS
});

export const snackUndoAction = (action) => {
    return async (dispatch) => {
        dispatch({type: SNACK_UNDO});
        dispatch(action);
    }
};