import { MARKETS_LOADING, MARKETS_LOADED, MARKET_LOADED } from '../actions/marketActions'

export default function marketsReducer(state={records: [], isLoading: false}, action) {
    switch(action.type) {

        case MARKETS_LOADING:
            return { ...state, isLoading: true };

        case MARKETS_LOADED:
            return { ...state, records: action.markets, isLoading: false };

        case MARKET_LOADED:
            return { ...state, selected: action.market, records: [...state.records, action.market], isLoading: false };

        default:
            return state
    }
}
