import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

const pinkish = createMuiTheme({
    palette: {
        primary: indigo,
        secondary: pink,
        error: red,
    }
});



const dark = createMuiTheme({
    palette: {
        type: 'dark'
    }
});

const light = createMuiTheme({
    palette: {
        type: 'light'
    }
});


export default light;