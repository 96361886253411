import React, { Component } from 'react';
import classNames from 'classnames';
import withStyles  from '@material-ui/core/styles/withStyles';
import {Paper, Typography, TextField, List, ListItem, ListItemText} from '@material-ui/core';
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import {StyledCircularProgress} from "../../components/Progress";
import {getMarketAction} from "../../redux/actions/index";
import {getSolutionAction} from "../../redux/actions";
import {connect} from "react-redux";

const styles = theme => ({

});
class MarketsShowPage extends React.Component {

    componentWillMount() {
        console.log("Market Page Props", this.props)
        const id = this.props.match.params.id;
        if (id) {
            console.log("Need to load a market", id)
            this.props.getMarketAction(id)
        }
    }

    render() {
        const {market, classes, isLoading} = this.props;

        const marketIsLoading = isLoading || !market;

        if (marketIsLoading) {
            console.log("market is loading", market)
        } else {
            console.log("Load Complete", market)
        }


        return (
            <Paper>
                <Toolbar>
                    <Typography variant="title">Market</Typography>
                </Toolbar>
                { marketIsLoading ? (
                    <StyledCircularProgress />
                ) : (
                    <React.Fragment>
                        <MarketView market={market}/>
                    </React.Fragment>
                )}
            </Paper>
        )
    }
}

const MarketView = ({market}) => (
    <List dense={true}>
        <ListItem><ListItemText primary="Name" secondary={market.name}/></ListItem>
        <ListItem><ListItemText primary="Default Price" secondary={market.defaultPrice}/></ListItem>
        <ListItem><ListItemText primary="Fail Cost" secondary={market.failCost}/></ListItem>
        <ListItem><ListItemText primary="Market Type" secondary={market.marketType}/></ListItem>
    </List>
);

const mapStateToProps = (state) => ({
    isLoading: state.markets.isLoading,
    market: state.markets.selected,
})

MarketsShowPage = connect(mapStateToProps, {getMarketAction})(MarketsShowPage);

export default MarketsShowPage

// export default withStyles(styles)(MarketsShowPage);