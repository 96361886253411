import React, { Component } from 'react';
import classNames from 'classnames';
import withStyles  from '@material-ui/core/styles/withStyles';
import {Paper, Typography, TextField} from '@material-ui/core';
import * as CONSTANTS from "../constants";

const styles = theme => ({
    helperText: {
        "fontFamily": "Roboto-Mono",
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});

const DevelopersPage = (props) => (
    <div>
        <Paper>

            <Typography id="title" component="h3" variant="title">Developers</Typography>

            <Typography className={props.classes.helperText}>Test sdtring</Typography>

            {/*<TextField*/}
                {/*multiLine={true}*/}
                {/*rows={2}*/}
                {/*rowsMax={4}*/}
            {/*></TextField>*/}

            <Typography style={{wordWrap: 'break-word', whiteSpace: 'normal'}} component="p">{localStorage.getItem(CONSTANTS.USER_JWT)}</Typography>
        </Paper>
    </div>
);

export default withStyles(styles)(DevelopersPage);