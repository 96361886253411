import React from 'react';
import GoogleLogin from 'react-google-login';
import { GOOGLE_APP_CLIENT_ID, GOOGLE_HOSTED_DOMAIN } from '../constants'

import { googleAuthSuccessAction } from '../redux/actions'
import { googleAuthFailureAction } from '../redux/actions'
import {connect} from "react-redux";


const GoogleLoginButton = (props) => (
    <GoogleLogin
        clientId={GOOGLE_APP_CLIENT_ID}
        buttonText="Login"
        hostedDomain={GOOGLE_HOSTED_DOMAIN}
        onSuccess={props.success}
        onFailure={props.failure}
    />
);

// Redux Work
const mapStateToProps = (state) => {
    return { auth: state.auth }
};
const mapDispatchToProps = (dispatch) => {
    return {
        success(response) { dispatch(googleAuthSuccessAction(response))},
        failure(response) { dispatch(googleAuthFailureAction(response))}
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleLoginButton)