//https://github.com/maxnachlinger/redux-websocket-example

import Immutable, { List } from 'immutable'

const MESSAGE_ADDED = 'MESSAGE_ADDED';

export default function messagesReducer(state={records: new List()}, action) {

    switch (action.type) {

        case MESSAGE_ADDED:
            return {...state, records: state.records.push(Immutable.fromJS(action.payload))};

        default:
            return state
    }
}
