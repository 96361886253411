import React from 'react';
import PropTypes from 'prop-types';
import {TableBody, TableCell, TableHead, TableRow, Table } from "@material-ui/core";
import {Link} from "react-router-dom";
import split from 'lodash/split';
import {FormattedDateTime} from "./FormattedDateTime";


function ResourceReadingsTable({readings}) {

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>ReadingTime</TableCell>
                    <TableCell>Quanta</TableCell>
                    <TableCell>Import Avg</TableCell>
                    <TableCell>Odometer Max</TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {readings.map(r => {
                    return (
                        <TableRow key={r.id}>
                            <TableCell><FormattedDateTime value={r.readingTime}/></TableCell>
                            <TableCell>{r.quanta}</TableCell>
                            <TableCell>{r.importKWhAvg}</TableCell>
                            <TableCell>{r.odometerKWhMax}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

export default ResourceReadingsTable;
