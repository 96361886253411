import React from 'react'
import { Field, reduxForm } from 'redux-form'
import asyncValidate from './asyncValidate'
import {MenuItem, Radio, RadioGroup, Select, TextField, Checkbox} from '@material-ui/core';
import {withTheme} from '@material-ui/core'

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    menu: {
        width: 200,
    },
});




// const validate = values => {
//     const errors = {}
//     const requiredFields = [
//         'firstName',
//         'lastName',
//         'email',
//         'favoriteColor',
//         'notes'
//     ]
//     requiredFields.forEach(field => {
//         if (!values[field]) {
//             errors[field] = 'Required'
//         }
//     })
//     if (
//         values.email &&
//         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
//     ) {
//         errors.email = 'Invalid email address'
//     }
//     return errors
// }

const renderTextField = ({
                             input,
                             label,
                             meta: { touched, error },
                             ...custom
                         }) => (
    <TextField
        helperText={label}
        placeholder={label}
        // errortext={touched && error}
        errortext={touched ? error : undefined}
        {...input}
        {...custom}
    />
)

const renderCheckbox = ({ input, label }) => (
    <Checkbox
        label={label}
        checked={!!input.value}
        onChange={input.onChange}
    />
)

const renderRadioGroup = ({ input, ...rest }) => (
    <RadioGroup
        {...input}
        {...rest}
        valueselected={input.value}
        onChange={(event, value) => input.onChange(value)}
    />
)

const renderSelectField = ({
                               input,
                               label,
                               meta: { touched, error },
                               children,
                               ...custom
                           }) => (
    <Select
        floatinglabeltext={label}
        errortext={touched ? error : undefined}
        {...input}
        onChange={(event, index, value) => input.onChange(value)}
        children={children}
        {...custom}
    />
)

const MaterialUiForm = props => {
    const { handleSubmit, pristine, reset, submitting } = props
    return (
        <form onSubmit={handleSubmit}>
            <div>
                <Field
                    name="firstName"
                    component={renderTextField}
                    label="First Name"
                />
            </div>
            <div>
                <Field name="lastName" component={renderTextField} label="Last Name" />
            </div>
            <div>
                <Field name="email" component={renderTextField} label="Email" />
            </div>
            <div>
                <Field name="sex" component={renderRadioGroup}>
                    <Radio value="male" label="male" />
                    <Radio value="female" label="female" />
                </Field>
            </div>
            <div>
                <Field
                    name="favoriteColor"
                    component={renderSelectField}
                    label="Favorite Color"
                >
                    <MenuItem value="ff0000" primaryText="Red" />
                    <MenuItem value="00ff00" primaryText="Green" />
                    <MenuItem value="0000ff" primaryText="Blue" />
                </Field>
            </div>
            <div>
                <Field name="employed" component={renderCheckbox} label="Employed" />
            </div>
            <div>
                <Field
                    name="notes"
                    component={renderTextField}
                    label="Notes"
                    multiline={true}
                    rows={2}
                />
            </div>
            <div>
                <button type="submit" disabled={pristine || submitting}>
                    Submit
                </button>
                <button type="button" disabled={pristine || submitting} onClick={reset}>
                    Clear Values
                </button>
            </div>
        </form>
    )
}

let styled = withTheme()(MaterialUiForm);

export default reduxForm({
    form: 'MaterialUiForm', // a unique identifier for this form
    // validate,
    // asyncValidate
})(styled)
