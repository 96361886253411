export const GOOGLE_ACCESS_TOKEN="cinetGoogleAccessToken";
export const GOOGLE_ID_TOKEN="cinetGoogleIdToken";

export const USER_ACCESS_TOKEN = "adminWebJwtUserAccessToken";
export const USER_PROFILE_OBJECT = "adminWebJwtUserProfileObject";
export const USER_JWT = "adminWebJwtUserJwt";
export const APPLICATION_JSON_UTF8 = "application/json; charset=utf-8";
export const AUTHORIZATION = "Authorization";

export const GOOGLE_APP_CLIENT_ID=process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_HOSTED_DOMAIN=process.env.REACT_APP_GOOGLE_HOSTED_DOMAIN;
export const API_HOST=process.env.REACT_APP_API_HOST;