//https://blog.scalac.io/react-redux-jwt-authentication.html

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export default function (ComposedComponent) {
    class NoAuthRequired extends Component {

        static getDerivedStateFromProps(props, state) {
            if (props.authenticated) {
                console.log("allowing as authentication not required")
            }
        }

        // componentWillMount() {
        //     if (this.props.authenticated) {
        //         console.log("allowing as authentication not required")
        //         // this.props.history.push('/secret');
        //     }
        // }
        //
        // componentWillUpdate(nextProps) {
        //     if (nextProps.authenticated) {
        //         console.log("allowing as authentication not required")
        //         // this.props.history.push('/secret');
        //     }
        // }

        PropTypes = {
            router: PropTypes.object,
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return { authenticated: state.auth.authenticated };
    }

    return connect(mapStateToProps)(NoAuthRequired);
}