import React from 'react';
import {Paper, Typography} from '@material-ui/core';
import LoginButton from '../containers/LoginButton'

export const LoginPage = () => (
    <div>
        <Paper>
            <Typography>LoginPage</Typography>
            <Typography>You need to Sign in to server</Typography>
            <LoginButton/>



            {/*{googleLoginNV}*/}

        </Paper>




    </div>
);