import React, { Component } from 'react';
import classNames from 'classnames';
import withStyles  from '@material-ui/core/styles/withStyles';
import {Paper, Typography, List, ListItem, ListItemText, Table, TableBody} from '@material-ui/core';
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import {StyledCircularProgress} from "../components/Progress";
import {getGridEventAction} from "../redux/actions";
import {connect} from "react-redux";
import {RowData} from "../components/RowData";
import {shortId} from "../util/functions";

const styles = theme => ({
    paper: {
        marginBottom: '10px'
    }

});
class GridEventPage extends React.Component {

    componentWillMount() {
        const id = this.props.match.params.id;
        if (id) {
            this.props.getGridEventAction(id)
        }
    }

    render() {
        const {gridEvent, classes, isLoading} = this.props;

        const gridEventIsLoading = isLoading || !gridEvent;


        return (
            <React.Fragment>
            <Paper className={classes.paper}>
                <Toolbar>
                    <Typography variant="title">Grid Event</Typography>
                </Toolbar>
            </Paper>
                { gridEventIsLoading ? (
                    <StyledCircularProgress />
                ) : (

                        <GridEventView {...this.props}/>

                )}
            </React.Fragment>

        )
    }
}
/*
<TableCell>Id</TableCell>
                    <TableCell>First Detected</TableCell>
                    <TableCell>Last Detected</TableCell>
                    <TableCell>GridPoints</TableCell>
                    <TableCell>Actions</TableCell>
 */

const GridEventView = ({gridEvent, classes}) => (
    <Paper className={classes.paper}>
        <Toolbar>
        <Typography variant="subheading" color="textSecondary">Properties</Typography>
        </Toolbar>
        <Table><TableBody>
            <RowData name="ID" value={shortId(gridEvent.id)}/>
            <RowData name="First Detected" date={gridEvent.firstDetected}/>
            <RowData name="Last Detected" date={gridEvent.lastDetected}/>
            <RowData name="GridPoints" value={gridEvent.gridPoints}/>
            <RowData name="Actions" value={gridEvent.actions.map(a => { return `${a.actionType} at ${a.actionAt}`})}/>
        </TableBody></Table>
    </Paper>
);

const mapStateToProps = (state) => ({
    isLoading: state.gridEvents.isLoading,
    gridEvent: state.gridEvents.selected,
})

GridEventPage = connect(mapStateToProps, {getGridEventAction})(GridEventPage);

export default withStyles(styles)(GridEventPage);