import React from 'react';
import {Select, TextField, RadioGroup} from "@material-ui/core";
import {DateTimePicker} from "material-ui-pickers";
import DateRange from '@material-ui/icons/DateRange';
import AccessTime from '@material-ui/icons/AccessTime';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight  from '@material-ui/icons/KeyboardArrowRight';




//
// const ReduxFormDateRange = (props) => {
//     return (
//         <DatePicker
//             selected={props.input.value || null}
//             onChange={props.input.onChange}
//         />
//     )
// }
// export const renderDatePicker = ({
//                                      input: { onChange, name, value }, label, meta: { touched, error }, classes
//                                  }) => (
// export const renderDatePicker = ({
//                                      onChange, value, label, classes
//                                  }) => (



export const renderRadioGroup = ({ input, ...rest }) => (
    <RadioGroup
        {...input}
        {...rest}
        valueSelected={input.value}
        onChange={(event, value) => input.onChange(value)}
    />
)




export const renderDatePicker = (props) => {

    console.log("renderDatePicker props", props)

    const {onFieldChange, value, label, classes, input} = props

    const handleDateChange = (date) => {
        console.log('handleDateChange', date)
        // input.onChange({name: input.name, value: date})
        input.onChange(date)
        // onFieldChange({date: date})
    };

    return (

    <DateTimePicker
        label={label}
        className={classes.dateTimeField}
        timeIcon={<AccessTime/>}
        dateRangeIcon={<DateRange/>}
        leftArrowIcon={<KeyboardArrowLeft/>}
        rightArrowIcon={<KeyboardArrowRight/>}
        value={input.value}
        //onChange={input.onChange}
        // onChange={(event, value) => input.onChange(value)}
        onChange={handleDateChange}
        // onClick={() => this.handleClick(id)}

        // format="D MMM YYYY"
    />)
};

export const renderTextField = (
    { input, label, help, multiline, required, meta: { touched, error }, classes, ...custom },
) => (
        <TextField
            label={label}
            className={classes.textField}
            error={ !!(touched && error) }
            helperText={touched && error}
            margin="normal"
            multiline={multiline}
            {...input}
            {...custom}
        />
);

export const renderSelectField = ({
                               input,
                               label,
                               meta: { touched, error },
                               classes,
                               children
                           }) => (

    <Select
        native
        label={label}
        children={children}
        {...input}
    />
);